import { createContext, PropsWithChildren, useContext, useState } from "react";
import Modal from "components/shared/Modal/Modal.component";
import Text from "components/shared/Text/Text.component";
import errorDataImage from "assets/images/error_data_loading.png";
import waitingOtpCodeImage from "assets/images/waiting_otp_code.png";
import style from "./GenericModal.module.css";
import { useTranslation } from "providers/Translation/Translation.provider";
import Button from "components/shared/Button/Button.component";
import {
  IGenericModalContext,
  IGenericModalOptions,
} from "./GenericModal.interface";
import { useClickstream } from "providers/Clickstream/Clickstream.provider";

const GenericModalContext = createContext({} as IGenericModalContext);

export const useGenericModal = () => useContext(GenericModalContext);

const GenericModalProvider = ({ children }: PropsWithChildren) => {
  const translate = useTranslation();
  const [show, setShow] = useState(false);
  const [option, setOptions] = useState<IGenericModalOptions>({
    template: "error",
  });
  const { track } = useClickstream();

  const showGenericModal = (options?: IGenericModalOptions) => {
    if (!show && options?.template === "error")
      track({
        eventName: "[GMWO] Error Information Shown",
        error: {
          code: options?.errorCode,
          message:
            options?.message ||
            translate(`generic.modal.${template}.text.message`),
        },
        navigation: { action: "Loaded" },
      });
    options && setOptions(options);
    setShow(true);
  };

  const hideGenericModal = () => setShow(false);

  const context: IGenericModalContext = {
    showGenericModal,
    hideGenericModal,
  };

  const {
    template,
    buttonSecondaryText,
    buttonPrimaryText,
    imageSrc,
    message,
    dismissable,
    buttonAlignment,
    onSecondaryButtonClick,
    onPrimaryButtonClick,
    title,
  } = option;

  const defaultButtonAlignment = !buttonAlignment
    ? "vertical"
    : buttonAlignment;

  const defaultImage =
    template === "error" ? errorDataImage : waitingOtpCodeImage;

  return (
    <GenericModalContext.Provider value={context}>
      {children}
      <Modal show={show} onClose={dismissable ? hideGenericModal : undefined}>
        <Modal.Body>
          <img
            src={imageSrc || defaultImage}
            alt={`${template}`}
            className={style["generic-modal__image"]}
          />

          <Text
            textAlignCenter
            bold
            fontSize="l"
            className={style["generic-modal__title"]}>
            {title || translate(`generic.modal.${template}.text.title`)}
          </Text>
          <Text
            textAlignCenter
            fontSize="s"
            className={style["generic-modal__message"]}>
            {message || translate(`generic.modal.${template}.text.message`)}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <div
            className={
              defaultButtonAlignment === "horizontal"
                ? style["generic-modal__footer--horizontal-alignment"]
                : ""
            }>
            <Button fullWidth onClick={onPrimaryButtonClick}>
              {buttonPrimaryText ||
                translate(`generic.modal.${template}.button.positive`)}
            </Button>
            {template === "confirm" && (
              <Button
                variant="secondary"
                fullWidth
                onClick={onSecondaryButtonClick}
                className={
                  style[
                    `generic-modal__button-negative-${defaultButtonAlignment}`
                  ]
                }>
                {buttonSecondaryText ||
                  translate(`generic.modal.${template}.button.negative`)}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </GenericModalContext.Provider>
  );
};

export default GenericModalProvider;
