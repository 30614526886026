import { useContainerApi } from "hooks/useContainerApi";
import { IGetContainerData } from "interfaces/container.interface";
import { useAuth } from "providers/Auth/Auth.provider";
import { useGenericModal } from "providers/GenericModal/GenericModal.provider";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { sortByPropertyName } from "../../utils/sortByPropertyName.util";
import { IFacetData } from "../../interfaces/facet.interface";
import { JSONTry } from "utils/jsonTry.util";
import { IErrors } from "screens/OwnerKtpDetails/OwnerKtpDetails.interface";
import { useTranslation } from "../Translation/Translation.provider";
import { SESSION_NAMES } from "constant/session.constant";
import { deleteSession } from "utils/session.util";
import {
  HARD_REJECTED_ERROR_CODES,
  SOFT_REJECTED_ERROR_CODES,
} from "../../constant/error.constant";
import { isErrorCodeIncluded } from "../../utils/isErrorCodeIncluded.util";

interface ILoadingStep {
  businessOwner: boolean;
  bankAccount: boolean;
  businessInfo: boolean;
}
interface IContainerContext {
  container: IGetContainerData;
  loadingStep: ILoadingStep;
  getLatestFacetData: (
    facetName: any,
    currentContainer?: IGetContainerData
  ) => IFacetData<any> | null;
  getLatestFacetErrors: (facetQuerys: any[]) => any[];
  setLoadingStep: (loadingStep: Partial<ILoadingStep>) => void;
  getRejectedErrors: (currentContainer?: IGetContainerData) => {
    rejectedErrors: string[];
    isSoftReject: boolean;
  };
}

const ContainerContext = createContext<IContainerContext>(
  {} as IContainerContext
);

export const useContainer = () => useContext(ContainerContext);

const ContainerProvider = ({ children }: PropsWithChildren) => {
  const { getContainer, searchContainerId } = useContainerApi();
  const [loadingStep, _setLoadingStep] = useState({
    businessOwner: false,
    bankAccount: false,
    businessInfo: false,
  });
  const [container, setContainer] = useState<IGetContainerData>(
    {} as IGetContainerData
  );
  const translate = useTranslation();
  const { user } = useAuth();
  const { params } = useRouteMatch<{ containerId: string }>();
  const { location, goBack, replace } = useHistory();
  const { showGenericModal, hideGenericModal } = useGenericModal();

  const setLoadingStep = (data: Partial<ILoadingStep>) => {
    _setLoadingStep({ ...loadingStep, ...data });
  };

  const fetchContainer = async () => {
    try {
      const response = await getContainer(params.containerId);
      if (!response.ok) throw new Error(response.status.toString());

      return response;
    } catch (err) {
      const error = err as Error;

      if (error.message !== "403") {
        showGenericModal({
          template: "error",
          onPrimaryButtonClick: () => {
            goBack();
            hideGenericModal();
          },
        });
        return;
      }

      const shouldShowError = await searchValidContainer();
      shouldShowError && showErrorDialog();
    }
  };

  const showErrorDialog = () =>
    showGenericModal({
      template: "error",
      errorCode: "401",
      title: translate("generic.modal.401.text.title"),
      message: translate("generic.modal.401.text.message"),
      buttonPrimaryText: translate("generic.modal.401.button.primary"),
      onPrimaryButtonClick: () => {
        deleteSession(SESSION_NAMES.ACCESS_TOKEN);
        deleteSession(SESSION_NAMES.REFRESH_TOKEN);
        hideGenericModal();
        replace("/");
      },
    });

  const searchValidContainer = async () => {
    const response = await searchContainerId(user.id.toString());

    if (response.ok && response.data.data.length > 0) {
      const lastPath = location.pathname.substring(
        location.pathname.lastIndexOf("/"),
        location.pathname.length
      );

      replace(`/${response.data.data[0].id}/${lastPath}`);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!user?.id || !params.containerId) return;

    (async () => {
      const response = await fetchContainer();
      if (!response) return;
      stayOnOverviewPage(response.data.data.meta.status);
      setContainer(response.data.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, params.containerId, location.pathname]);

  const stayOnOverviewPage = (containerStatus: string) => {
    const shouldRedirect = ["SUBMITTED", "COMPLETED"].includes(containerStatus);
    if (shouldRedirect) replace(`/${params.containerId}/overview`);
  };

  const getLatestFacetData = (
    facetQuery: any,
    currentContainer?: IGetContainerData
  ) => {
    const relatedFacetData = (currentContainer || container)?.processes?.filter(
      (process) => {
        return Object.keys(facetQuery).reduce((acc, key: string) => {
          return acc && JSONTry(process, key) === facetQuery[key];
        }, true);
      }
    );
    return relatedFacetData?.length > 0
      ? sortByPropertyName(relatedFacetData, "-meta.created_at")[0]
      : null;
  };

  const getLatestFacetErrors = (
    facetQuerys: any[],
    currentContainer?: IGetContainerData
  ) => {
    const result = facetQuerys.reduce((acc: IErrors[], facetQuery) => {
      const facetErrors = getLatestFacetData(facetQuery, currentContainer)?.meta
        .errors;

      if (facetErrors?.length > 0) {
        return acc.concat(facetErrors);
      }
      return acc;
    }, []);

    const details = result?.map(
      (data: IErrors) =>
        `follow_up_reasons.${data?.code?.toString().toLowerCase()}`
    );

    return Array.from(new Set(details));
  };

  const getRejectedErrors = (currentContainer?: IGetContainerData) => {
    const ERROR_CODES = SOFT_REJECTED_ERROR_CODES.concat(
      HARD_REJECTED_ERROR_CODES
    );

    const latestStatus = getLatestFacetData(
      {
        "meta.facet_name": "address",
      },
      currentContainer
    )?.meta?.status;

    return {
      rejectedErrors: getLatestFacetErrors(
        [
          {
            "meta.facet_name": "address",
          },
        ],
        currentContainer
      ).filter((errorCodeTranslationKey) => {
        return isErrorCodeIncluded(ERROR_CODES, errorCodeTranslationKey);
      }),
      isSoftReject: latestStatus === "SOFT_REJECT",
    };
  };

  return (
    <ContainerContext.Provider
      value={{
        container,
        loadingStep,
        setLoadingStep,
        getLatestFacetData,
        getLatestFacetErrors,
        getRejectedErrors,
      }}
      children={children}
    />
  );
};

export default ContainerProvider;
