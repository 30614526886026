const QuestionIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9853 18.7941C33.6053 8.34306 18.7883 0.946057 9.72734 10.2451C1.44334 18.7371 7.86334 33.8181 20.1803 33.9941C21.2243 34.0241 22.2463 33.9371 23.2083 33.7411C23.4003 33.7081 23.5893 33.6851 23.7823 33.6441C24.7533 33.4411 26.1173 32.9501 27.5333 32.0711C28.4663 32.4601 31.7713 33.7951 32.7803 33.6831C33.8443 33.5641 32.4533 28.2541 32.1703 27.2171C33.3543 25.0791 34.1143 22.3191 33.9853 18.7941Z"
        fill="#FFB300"
      />
      <path
        d="M22.2538 27.1534L22.3018 27.2364"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7248 18.1192C14.7248 18.1192 14.3808 15.5262 17.5458 13.7422C19.2138 12.8022 23.6508 12.6282 24.3888 15.8092C25.0338 18.5882 22.2868 19.5742 21.2868 20.9002C20.8878 21.4302 20.6798 22.1792 20.7528 22.6022"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QuestionIcon;
