/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
import { JSONTry } from "./jsonTry.util";

function dynamicSort(property: string) {
  let sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a: any, b: any) {
    const stringA = JSONTry(a, property, "");
    const stringB = JSONTry(b, property, "");

    if (sortOrder === -1) {
      return stringB.localeCompare(stringA);
    } else {
      return stringA.localeCompare(stringB);
    }
  };
}

export const sortByPropertyName = (array: any[], propertyName: string) => {
  return array.sort(dynamicSort(propertyName));
};
