import { ILanguageCode } from "providers/Language/Language.interface";
import { useLanguage } from "providers/Language/Language.provider";
import { createContext, useContext } from "react";
import { deepMerge } from "utils/deepMerge.util";
import { fromEnv } from "utils/env.util";
import { JSONTry } from "utils/jsonTry.util";
import { textParamsParser } from "utils/textParamParser.util";

const TranslationsContext = createContext({});

export const useTranslation = (translationFile: any = {}) => {
  const { language: _language } = useLanguage();
  const language =
    fromEnv("REACT_APP_HIDE_LANGUAGE_SELECTOR") === "true" ? "id" : _language;
  const translationContext = useContext(TranslationsContext);

  const mergedTranslation = deepMerge(translationContext, translationFile);

  const isDevelopment = process.env.NODE_ENV === "development";

  const fallBackLanguage: ILanguageCode = "en";

  const isTest = process.env.NODE_ENV === "test";

  return (path: string, params: string[] = [], missingMsg?: string): string => {
    const splitByDot = path.split(".");
    const [lastPath] = splitByDot.slice(-1);
    const missingStr = isDevelopment ? `missing_translation:${path}` : lastPath;

    let translated = JSONTry(
      mergedTranslation,
      `${language}.${path}`,
      missingMsg || missingStr
    );

    if (translated === lastPath) {
      translated = JSONTry(
        mergedTranslation,
        `${fallBackLanguage}.${path}`,
        lastPath
      );
    }

    if (translated === lastPath && isTest) {
      console.info("Missing translation: " + path);
    }

    return textParamsParser(translated, params);
  };
};

type TranslationProviderProps = {
  languageFile: any;
  children: React.ReactNode;
};

export const TranslationProvider = (props: TranslationProviderProps) => {
  const { languageFile, children } = props;

  return (
    <TranslationsContext.Provider value={languageFile}>
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationProvider;
