const InformationIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9858 12.7942C27.6048 2.34222 12.7878 -5.05478 3.72778 4.24522C-4.55722 12.7372 1.86378 27.8182 14.1808 27.9942C15.2248 28.0242 16.2468 27.9362 17.2088 27.7412C17.4008 27.7072 17.5888 27.6852 17.7828 27.6442C18.7538 27.4412 20.1178 26.9502 21.5338 26.0712C22.4668 26.4592 25.7708 27.7952 26.7808 27.6822C27.8448 27.5642 26.4538 22.2542 26.1708 21.2172C27.3548 19.0792 28.1148 16.3192 27.9858 12.7942Z"
        fill="#33BEDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3719 22.6596C12.8199 22.6596 12.2819 22.4576 11.8569 22.0776C11.2709 21.5526 10.9989 20.7746 11.1309 19.9996L12.4239 13.6106L12.3019 13.6366C11.4869 13.7926 10.6979 13.2736 10.5349 12.4626C10.3709 11.6506 10.8959 10.8586 11.7079 10.6946L12.7759 10.4786C13.5469 10.3326 14.3349 10.5636 14.8899 11.1066C15.4449 11.6486 15.6989 12.4296 15.5689 13.1946L14.3099 19.4096C14.9859 19.4026 15.6059 19.8236 15.7989 20.4976C16.0259 21.2946 15.5649 22.1246 14.7679 22.3516L14.0019 22.5706C13.7929 22.6306 13.5819 22.6596 13.3719 22.6596Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7992 8.89484C14.7382 8.89484 14.6772 8.89084 14.6152 8.88384C13.7922 8.78284 13.2072 8.03484 13.3082 7.21184L13.3202 7.11284C13.4212 6.29084 14.1682 5.71484 14.9922 5.80584C15.8142 5.90784 16.3992 6.65584 16.2982 7.47784L16.2862 7.57784C16.1932 8.33684 15.5462 8.89484 14.7992 8.89484Z"
        fill="white"
      />
    </svg>
  );
};

export default InformationIcon;
