import { createContext, useState, useContext } from "react";
import {
  ILitmusContext,
  LitmusProviderProps,
  LitmusResponse,
} from "./Litmus.types";
import { fromEnv } from "utils/env.util";
import { useFetcher } from "hooks/useFetcher";
import { getSession } from "utils/session.util";
import { SESSION_NAMES } from "constant/session.constant";
import { v4 as uuidv4 } from "uuid";

const defaultValue: ILitmusContext = {
  configs: [],
  loading: false,
  isHarmoniaRedirectEnable: async () => false,
  error: "INIT",
};

export const LitmusContext = createContext(defaultValue as ILitmusContext);
export const useLitmus = () => useContext(LitmusContext);

export const LitmusProvider = ({ children }: LitmusProviderProps) => {
  const [configs, setConfigs] = useState<LitmusResponse[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const fetch = useFetcher();

  const getConfigByReleaseName = async <T extends any>(releaseName: string) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${fromEnv(
          "REACT_APP_LITMUS_HOST"
        )}?unit-id=${uuidv4()}&unit-type=merchant`,
        {
          headers: {
            "x-client-id": fromEnv("REACT_APP_LITMUS_CLIENT_ID"),
            "x-passkey": fromEnv("REACT_APP_LITMUS_PASSKEY"),
            "Accept-Language": "id",
            "Gojek-Country-Code": "ID",
            Authorization: "Bearer " + getSession(SESSION_NAMES.ACCESS_TOKEN),
          },
        }
      );

      if (!response.ok)
        throw new Error(
          "response status: " +
            response.status +
            " " +
            JSON.stringify(response.data)
        );

      const getConfig = response.data.data.find(
        (config: any) => config.experiment === releaseName
      ) as LitmusResponse<T>;

      setConfigs(response.data.data);
      return getConfig;
    } catch (err) {
      const _err = err as any;
      setError(_err);
    } finally {
      setLoading(false);
    }
  };

  const isHarmoniaRedirectEnable = async (userId: string) => {
    const getConfig = await getConfigByReleaseName(
      fromEnv("REACT_APP_LITMUS_CONFIG_NAME")
    );

    if (error) return false;

    return (
      getConfig?.properties?.redirect_to_harmonia ||
      getConfig?.properties?.whitelisted_user_ids?.split(",").includes(userId)
    );
  };

  return (
    <LitmusContext.Provider
      value={{ configs, loading, error, isHarmoniaRedirectEnable }}>
      {children}
    </LitmusContext.Provider>
  );
};
