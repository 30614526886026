import Card from "components/shared/Card/Card.component";
import Container from "components/shared/Container/Container.component";
import Text from "components/shared/Text/Text.component";
import Title from "components/shared/Title/Title.component";
import { useTranslation } from "providers/Translation/Translation.provider";
import imageQuickLogin from "assets/images/quick_login.png";
import style from "./DesktopRedirectQR.module.css";
import { GopayMerchantIcon } from "components/shared/Icon";
import { classNames } from "utils/classnames.util";
import { fromEnv } from "utils/env.util";
import QRCode from "react-qr-code";

const DesktopRedirectQR = () => {
  const translate = useTranslation();

  return (
    <>
      <div className={classNames(style["desktop-redirect-qr__page-header"])}>
        <div data-testid="page-header-logo">
          <GopayMerchantIcon />
        </div>
      </div>
      <div className={style["desktop-redirect-qr"]}>
        <Container className={style["desktop-redirect-qr__container"]}>
          <Card type="nude" className={style["desktop-redirect-qr__card"]}>
            <div className={style["desktop-redirect-qr__left-section"]}>
              <Title>{translate("desktop_redirect_page.title")}</Title>
              <Text
                className={style["desktop-redirect-qr__subtitle"]}
                fontSize="l">
                {translate("desktop_redirect_page.message")}
              </Text>
              <div className={style["desktop-redirect-qr__qr-container"]}>
                <QRCode
                  className={style["desktop-redirect-qr__image-qr"]}
                  value={`${fromEnv(
                    "REACT_APP_ONBOARDING_URL"
                  )}?method=qr_code_scan`}
                />
                <div>
                  <Text className={style["desktop-redirect-qr__instruction"]}>
                    {translate("desktop_redirect_page.scan_instruction")}
                  </Text>
                  <Text bold>{fromEnv("REACT_APP_ONBOARDING_URL")}</Text>
                </div>
              </div>
            </div>
            <div>
              <img
                alt="qr gopay onboarding"
                className={style["desktop-redirect-qr__image"]}
                src={imageQuickLogin}
              />
            </div>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default DesktopRedirectQR;
