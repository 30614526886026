import { fromEnv } from "utils/env.util";

export const OUTLET_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/outlet/v1/process`;

export const CONTACT_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/contact/v1/process`;

export const ADDRESS_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/address/v1/process`;

export const REGISTER_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/v1/register`;

export const LOGIN_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/gobiz/goid/login/request`;

export const USERS_ME_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/v1/users/me`;

export const GOID_TOKEN_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/gobiz/goid/token`;

export const DEACTIVATE_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/v1/process/deactivate`;

export const CREATE_BANK_ACCOUNT_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/bank-account/v1/process`;

export const GET_PROCESS_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/v1/process`;

export const CREATE_CONSENT_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/consent/v1/process`;

export const PRODUCTS_SEARCH_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/v3/products/search`;

export const CONTRACTS_SEARCH_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/v4/contracts/search`;

export const FILE_UPLOAD_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/v3/documents/upload_url`;

export const KTP_DOCUMENT_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/document/v1/process`;

export const CONTAINER_API_URL = `${fromEnv(
  "REACT_APP_GOBIZ_API_HOST"
)}/v4/onboardings`;

export const SEARCH_CONTAINER_API_URL = `${CONTAINER_API_URL}/search`;

export const HARMONIA_TAG_ME_URL = `${fromEnv(
  "REACT_APP_HARMONIA_API_HOST"
)}/processes/me`;

export const OTP_CLIENT_ID = "gopay:gopay-merchant:mobile";
