export const isErrorCodeIncluded = (
  errorCodes: string[],
  errorCodeTranslationKey: string
) => {
  return (
    errorCodes.filter((rejectedErrorCode) =>
      errorCodeTranslationKey.includes(rejectedErrorCode)
    ).length > 0
  );
};
