import { COOKIE_NAMES } from "constant/cookie.constant";
import { createContext, useContext, useState } from "react";
import { getCookie, setCookie } from "utils/cookies.util";
import type {
  ILanguageCode,
  ILanguageContext,
  ILanguageProps,
} from "./Language.interface";

const LanguageContext = createContext({} as ILanguageContext);

export const useLanguage = () => useContext(LanguageContext);

const LanguageProvider = ({ children }: ILanguageProps) => {
  const isValidLanguage = (language: string) => ["id", "en"].includes(language);

  const defaultLanguage = ((isValidLanguage(getCookie(COOKIE_NAMES.LANGUAGE)) &&
    getCookie(COOKIE_NAMES.LANGUAGE)) ||
    "en") as ILanguageCode;

  const [language, _setLanguage] = useState<ILanguageCode>(defaultLanguage);

  const setLanguage = (language: ILanguageCode) => {
    setCookie(COOKIE_NAMES.LANGUAGE, language);
    _setLanguage(language);
  };

  const context = {
    language,
    setLanguage,
  };

  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
