import { fromEnv } from "utils/env.util";

export const HELP_URLS = {
  HOME: `${fromEnv("REACT_APP_HELP_URL")}`,
  SIGN_UP: `${fromEnv("REACT_APP_HELP_URL")}/article/Sign-Up`,
  LOGIN: `${fromEnv("REACT_APP_HELP_URL")}/article/Login`,
  SUBMITTED: `${fromEnv("REACT_APP_HELP_URL")}/article/Submitted`,
  RESUBMISSION: `${fromEnv("REACT_APP_HELP_URL")}/article/Resubmission`,
  REGISTRATION_SUCCESS: `${fromEnv(
    "REACT_APP_HELP_URL"
  )}/article/Registration-Success`,
  REGISTRATION_SOFT_REJECTED: `${fromEnv(
    "REACT_APP_HELP_URL"
  )}/article/Registration-Rejected`,
  REGISTRATION_REJECTED: `${fromEnv(
    "REACT_APP_HELP_URL"
  )}/article/Mengapa-aktivasi-saya-ditolak`,
};
