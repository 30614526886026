import { createContext, PropsWithChildren } from "react";

interface IHttpProvider {
  injectToken?: boolean;
}

export const HttpContext = createContext<IHttpProvider>({});

export const HttpProvider = ({
  children,
  injectToken,
}: PropsWithChildren<IHttpProvider>) => {
  return (
    <HttpContext.Provider value={{ injectToken }}>
      {children}
    </HttpContext.Provider>
  );
};
