const ThumbsDownIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11796_62149"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="28"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H28V27.8468H0V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11796_62149)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.8145 10.7295L22.8335 11.7835C22.8335 11.7835 22.5475 14.2935 20.7135 15.8395C18.8795 17.3845 17.7395 20.2715 18.6705 23.5315C19.6025 26.7925 18.0435 28.2845 16.1515 27.7355C14.2595 27.1855 12.0655 20.7975 13.4375 18.3585C13.4375 18.3585 7.01954 19.4505 5.10854 19.8865C3.19854 20.3235 -0.0964594 17.3755 3.35954 15.3785C3.35954 15.3785 1.36554 15.6215 0.956541 13.8045C0.547541 11.9875 2.13354 10.6305 2.13354 10.6305C2.13354 10.6305 0.127541 10.1025 0.00554058 8.65245C-0.115459 7.20145 1.78554 5.99445 1.78554 5.99445C1.78554 5.99445 0.947541 6.08445 0.803541 4.63745C0.659541 3.19145 1.92554 2.73845 6.28354 1.69145C10.6415 0.645452 17.5425 -1.24055 20.6615 1.17945L25.2675 0.142452C25.7735 0.0284517 26.2745 0.349452 26.3755 0.850452L27.9705 8.90545C28.1315 9.71745 27.6265 10.5135 26.8145 10.7295Z"
          fill="#F8A9AF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.30158 12.9315C6.70258 13.3255 5.14558 13.8425 3.57958 14.3525C3.16458 14.4875 2.85258 14.9365 2.99658 15.3795C3.13058 15.7945 3.57858 16.1085 4.02358 15.9635C5.58958 15.4535 7.14658 14.9365 8.74558 14.5425C9.17058 14.4385 9.46558 13.9365 9.32958 13.5145C9.18658 13.0745 8.75658 12.8195 8.30158 12.9315Z"
        fill="#D18070"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87119 8.59356C5.30919 8.87756 3.75519 9.20756 2.19519 9.50256C1.76519 9.58356 1.48019 10.1246 1.61119 10.5306C1.76019 10.9896 2.17819 11.2006 2.63919 11.1146C4.20019 10.8196 5.75319 10.4896 7.31519 10.2046C7.74619 10.1256 8.02919 9.57956 7.89919 9.17656C7.74919 8.71456 7.33319 8.50856 6.87119 8.59356Z"
        fill="#D18070"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.70206 4.13369C4.41707 4.46669 3.12707 4.76769 1.84807 5.12869C1.42807 5.24769 1.12507 5.72569 1.26507 6.15669C1.40307 6.58569 1.84207 6.86769 2.29307 6.73969C3.57106 6.37869 4.86107 6.07769 6.14607 5.74469C6.56907 5.63469 6.86706 5.14169 6.73006 4.71669C6.58907 4.28069 6.15507 4.01569 5.70206 4.13369Z"
        fill="#D18070"
      />
    </svg>
  );
};

export default ThumbsDownIcon;
