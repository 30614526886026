import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

export const useQueryParams = <T = any>(): T => {
  const { location } = useHistory();
  const { search } = location;

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const paramsToObject = useCallback((entries: URLSearchParams) => {
    const result: { [key: string]: string } = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }, []);

  return paramsToObject(searchParams) as T;
};
