import { HTMLAttributes } from "react";
import { classNames } from "utils/classnames.util";
import style from "./Text.module.css";

export interface TextProps
  extends HTMLAttributes<HTMLParagraphElement | HTMLLabelElement> {
  as?: keyof Pick<JSX.IntrinsicElements, "p" | "label">;
  bold?: boolean;
  className?: string;
  textAlignCenter?: boolean;
  onClick?: () => void;
  color?: "brand" | "error" | "default" | "disabled";
  fontSize?: "xs" | "s" | "default" | "l";
  htmlFor?: string;
}

const Text = ({
  children,
  bold,
  className,
  textAlignCenter,
  color,
  onClick,
  fontSize = "default",
  as: As = "p",
  htmlFor,
  ...rest
}: TextProps) => {
  return (
    <As
      {...rest}
      htmlFor={htmlFor}
      onClick={onClick}
      className={classNames(
        style["text"],
        bold && style["text--bold"],
        style[`text__color--${color}`],
        style[`text__fontSize--${fontSize}`],
        textAlignCenter && style["text--align-center"],
        onClick && style["text--clickable"],
        className
      )}
    >
      {children}
    </As>
  );
};

export default Text;
