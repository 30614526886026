import {
  ICreateContainerRequest,
  ICreateContainerResponse,
  IGetContainerResponse,
  ISearchContainerRequest,
  ISearchContainerResponse,
} from "interfaces/container.interface";
import { useFetcher } from "./useFetcher";
import { getSession } from "utils/session.util";
import { SESSION_NAMES } from "constant/session.constant";
import {
  CONTAINER_API_URL,
  SEARCH_CONTAINER_API_URL,
} from "constant/api.constant";

export const useContainerApi = () => {
  const context = "gopay_offline_onboarding";
  const fetcher = useFetcher();

  const createContainerId = async (userId: string) => {
    const body: ICreateContainerRequest = {
      context,
      meta: {
        requested_by: `${userId}`,
        requested_source: "gopaymerchant-web",
        resource_version: "4.0",
      },
    };

    const response = await fetcher<ICreateContainerResponse>(
      CONTAINER_API_URL,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + getSession(SESSION_NAMES.ACCESS_TOKEN),
          "Authentication-Type": "go-id",
        },
        body: JSON.stringify(body),
      }
    );

    return response;
  };

  const searchContainerId = async (userId: string) => {
    const body: ISearchContainerRequest = {
      query: {
        context,
        "meta.requested_by": `${userId}`,
      },
    };

    const response = await fetcher<ISearchContainerResponse>(
      SEARCH_CONTAINER_API_URL,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + getSession(SESSION_NAMES.ACCESS_TOKEN),
          "Authentication-Type": "go-id",
        },
        body: JSON.stringify(body),
      }
    );

    return response;
  };

  const getContainer = async (containerId: string) => {
    const response = await fetcher<IGetContainerResponse>(
      `${CONTAINER_API_URL}/${containerId}`
    );

    return response;
  };

  const submitContainer = async (containerId: string) => {
    const response = await fetcher<{ success: boolean }>(
      `${CONTAINER_API_URL}/${containerId}/submit`,
      {
        method: "POST",
      }
    );

    return response;
  };

  return {
    createContainerId,
    searchContainerId,
    getContainer,
    submitContainer,
  };
};
