import { SESSION_NAMES } from "constant/session.constant";
import { v4 as uuidv4 } from "uuid";

export const getSessionId = () => {
  const sessionId = getSession(SESSION_NAMES.SESSION_ID);
  if (!sessionId) {
    const newSessionId = uuidv4();
    setSession(SESSION_NAMES.SESSION_ID, newSessionId);
    return newSessionId;
  }
  return sessionId;
};

export const getSession = (name: SESSION_NAMES) => {
  return sessionStorage.getItem(name);
};

export const setSession = (name: SESSION_NAMES, value: string) => {
  sessionStorage.setItem(name, value);
};

export const deleteSession = (name: SESSION_NAMES) => {
  sessionStorage.removeItem(name);
};

export const clearSession = () => {
  sessionStorage.clear();
};
