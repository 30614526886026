import AsphaltLoader from "@asphalt-react/loader";
import style from "./Loader.module.css";
import Modal from "components/shared/Modal/Modal.component";
import Text from "components/shared/Text/Text.component";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { useTranslation } from "providers/Translation/Translation.provider";

type ILoaderContext = {
  setMessage: (message: string) => void;
  showLoader: () => void;
  hideLoader: () => void;
};

const LoaderContext = createContext({} as ILoaderContext);

export const useLoader = () => useContext(LoaderContext);

const LoaderProvider = ({ children }: PropsWithChildren) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const translate = useTranslation();

  const showLoader = useCallback(() => setShow(true), []);
  const hideLoader = useCallback(() => setShow(false), []);

  const context = { showLoader, hideLoader, setMessage };

  return (
    <>
      <LoaderContext.Provider value={context}>
        {children}
        <Modal show={show} position="center" fullWidth={false}>
          <Modal.Header hideCloseIcon />
          <Modal.Body>
            <div className={style["loader"]}>
              <AsphaltLoader />
              <Text bold fontSize="l" className={style["loader__message"]}>
                {message || translate("generic.checking_your_data")}
              </Text>
            </div>
          </Modal.Body>
        </Modal>
      </LoaderContext.Provider>
    </>
  );
};

export default LoaderProvider;
