export const SOFT_REJECTED_ERROR_CODES = [
  "0003",
  "0004",
  "1020",
  "1021",
  "1022",
  "1093",
  "1094",
  "1095",
  "1109",
];

export const HARD_REJECTED_ERROR_CODES = [
  "1014",
  "1038",
  "1066",
  "1077",
  "1078",
  "1079",
  "1080",
  "1081",
  "1082",
  "1083",
  "1096",
  "1097",
];
