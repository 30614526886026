import { COOKIE_NAMES } from "constant/cookie.constant";

export class CookieConfig {
  // To scope each cookies by a unique user, we need to use the currently logged in users id
  // to identify the cookies.
  static userId: number;

  static scopedCookieName = (key: COOKIE_NAMES | string) => {
    if (key.match(/^\$_/i)) {
      return `${key}:${CookieConfig.userId}`;
    }
    return key;
  };
}

type CookieExpiry = number | "never_expired";

export const getCookie = (cookieName: COOKIE_NAMES, _document = document) => {
  const scopedName = CookieConfig.scopedCookieName(cookieName);
  const cookie = scopedName + "=";
  const cookies = _document.cookie.split(";");
  for (let value of cookies) {
    while (value.charAt(0) === " ") {
      value = value.substring(1);
    }
    if (value.indexOf(cookie) === 0) {
      return value.substring(cookie.length, value.length);
    }
  }
  return "";
};

export const setCookie = (
  cookieName: COOKIE_NAMES,
  cookieValue: string,
  expiryDays: CookieExpiry = 10 * 365,
  _document: Document = document
) => {
  if (typeof expiryDays === "string") {
    expiryDays = 10 * 365;
  }

  const scopedName = CookieConfig.scopedCookieName(cookieName);
  const expiryDate = new Date();

  expiryDate.setTime(expiryDate.getTime() + expiryDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + expiryDate.toUTCString();
  _document.cookie = scopedName + "=" + cookieValue + ";" + expires + ";path=/";
};

export const deleteCookie = (
  cookieName: COOKIE_NAMES,
  _document: Document = document
) => {
  setCookie(cookieName, "", -1, _document);
};
