const TrackIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.79 6H11.2C10.2832 6 9.54004 6.74321 9.54004 7.66V32.34C9.54004 33.2568 10.2832 34 11.2 34H28.79C29.7068 34 30.45 33.2568 30.45 32.34V7.66C30.45 6.74321 29.7068 6 28.79 6Z"
        fill="#DE1994"
      />
      <path d="M16.5798 16.48H13.3198V19.68H16.5798V16.48Z" fill="#FFD41C" />
      <path d="M26.6902 10.48H23.4302V19.68H26.6902V10.48Z" fill="#FFD41C" />
      <path
        d="M25.5298 22.45H14.4798C13.8392 22.45 13.3198 22.9693 13.3198 23.61C13.3198 24.2506 13.8392 24.77 14.4798 24.77H25.5298C26.1705 24.77 26.6898 24.2506 26.6898 23.61C26.6898 22.9693 26.1705 22.45 25.5298 22.45Z"
        fill="#F3BBDF"
      />
      <path d="M21.6301 13.48H18.3701V19.68H21.6301V13.48Z" fill="#FFD41C" />
      <path
        d="M21.5298 27.21H14.4798C13.8392 27.21 13.3198 27.7293 13.3198 28.37C13.3198 29.0106 13.8392 29.53 14.4798 29.53H21.5298C22.1705 29.53 22.6898 29.0106 22.6898 28.37C22.6898 27.7293 22.1705 27.21 21.5298 27.21Z"
        fill="#F3BBDF"
      />
    </svg>
  );
};

export default TrackIcon;
