import {
  REGISTER_API_URL,
  LOGIN_API_URL,
  GOID_TOKEN_API_URL,
  USERS_ME_API_URL,
  HARMONIA_TAG_ME_URL,
} from "constant/api.constant";
import {
  IRegisterRequest,
  IRegisterResponse,
  ILoginRequest,
  ILoginResponse,
  ISubmitOTPRequest,
  IGoidResponse,
  IUserMeResponse,
} from "interfaces/auth.interface";
import { useFetcher } from "hooks/useFetcher";
import { getSession } from "utils/session.util";
import { SESSION_NAMES } from "constant/session.constant";

export const useAuthApi = () => {
  const fetcher = useFetcher();

  const getRegisterAPIResponse = async (props: IRegisterRequest) => {
    const response = await fetcher<IRegisterResponse>(REGISTER_API_URL, {
      method: "POST",
      body: JSON.stringify(props),
      headers: {
        "Accept-Language": "id",
      },
    });

    return response;
  };

  const getLoginAPIResponse = async (props: ILoginRequest) => {
    const response = await fetcher<ILoginResponse>(LOGIN_API_URL, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + getSession(SESSION_NAMES.ACCESS_TOKEN),
        "Authentication-Type": "go-id",
        "Accept-Language": "id",
      },
      body: JSON.stringify(props),
    });

    return response;
  };

  const getSubmitOTPResponse = async (props: ISubmitOTPRequest) => {
    const response = await fetcher<IGoidResponse>(GOID_TOKEN_API_URL, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + getSession(SESSION_NAMES.ACCESS_TOKEN),
        "Authentication-Type": "go-id",
      },
      body: JSON.stringify(props),
    });

    return response;
  };

  const getUserMeResponse = async () => {
    const response = await fetcher<IUserMeResponse>(USERS_ME_API_URL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getSession(SESSION_NAMES.ACCESS_TOKEN),
        "Authentication-Type": "go-id",
      },
    });

    return response;
  };

  const getHarmoniaMeResponse = async () => {
    const response = await fetcher(HARMONIA_TAG_ME_URL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getSession(SESSION_NAMES.ACCESS_TOKEN),
      },
    });

    return response;
  };

  return {
    getRegisterAPIResponse,
    getLoginAPIResponse,
    getSubmitOTPResponse,
    getUserMeResponse,
    getHarmoniaMeResponse,
  };
};
