const HelpIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="default/business/mini_spot/help">
        <g id="default/business/mini_spot/help_2">
          <g id="default/business/mini_spot/help_3">
            <path
              id="Fill 2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.9853 18.7941C33.6053 8.34306 18.7883 0.946057 9.72734 10.2451C1.44334 18.7371 7.86334 33.8181 20.1803 33.9941C21.2243 34.0241 22.2463 33.9371 23.2083 33.7411C23.4003 33.7081 23.5893 33.6851 23.7823 33.6441C24.7533 33.4411 26.1173 32.9501 27.5333 32.0711C28.4663 32.4601 31.7713 33.7951 32.7803 33.6831C33.8443 33.5641 32.4533 28.2541 32.1703 27.2171C33.3543 25.0791 34.1143 22.3191 33.9853 18.7941Z"
              fill="#FFB300"
            />
            <path
              id="Stroke 6"
              d="M22.2543 27.1533L22.3023 27.2363"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Stroke 4"
              d="M14.7248 18.1193C14.7248 18.1193 14.3808 15.5263 17.5458 13.7423C19.2138 12.8023 23.6508 12.6283 24.3888 15.8093C25.0338 18.5883 22.2868 19.5743 21.2868 20.9003C20.8878 21.4303 20.6798 22.1793 20.7528 22.6023"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HelpIcon;
