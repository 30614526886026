import { HTMLAttributes } from "react";
import { classNames } from "utils/classnames.util";
import style from "./Container.module.css";

interface Props extends HTMLAttributes<any> {
  className?: string;
  as?: keyof JSX.IntrinsicElements;
}

const Container = ({ children, className, as: As = "div", ...rest }: Props) => {
  return (
    <As {...rest} className={classNames(style["container"], className)}>
      {children}
    </As>
  );
};

export default Container;
