const CameraSimpleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12788_103847)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 15.5C10.3446 15.5 9 14.1554 9 12.5C9 10.8446 10.3446 9.5 12 9.5C13.6554 9.5 15 10.8446 15 12.5C15 14.1554 13.6554 15.5 12 15.5ZM18.0008 8.5C17.448 8.5 17 8.05231 17 7.5C17 6.94769 17.448 6.5 18.0008 6.5C18.552 6.5 19 6.94769 19 7.5C19 8.05231 18.552 8.5 18.0008 8.5ZM21.5888 7.75113C21.3306 6.24559 20.0802 5.09908 18.5624 4.91503C17.4509 4.78077 16.3424 4.68271 15.2324 4.61483C15.2309 4.59069 15.2309 4.56806 15.2294 4.54393C15.166 4.02498 14.7461 3.61314 14.2236 3.56185C13.8173 3.51961 13.4081 3.5 13.0003 3.5C12.5911 3.5 10.1838 3.51961 9.77453 3.56185C9.25351 3.61314 8.83518 4.02498 8.77175 4.54393C8.76873 4.56806 8.76873 4.59069 8.76722 4.61483C7.65722 4.68271 6.54722 4.78077 5.43873 4.91503C3.92098 5.10059 2.67204 6.24408 2.41229 7.74811C1.86257 10.9206 1.86257 14.0961 2.41229 17.2687C2.67204 18.7727 3.92249 19.9162 5.44024 20.1002C7.62551 20.3657 9.81379 20.5 12.0006 20.5C14.1858 20.5 16.3726 20.3657 18.5624 20.1002C20.0802 19.9162 21.3306 18.7727 21.5888 17.2671C22.1371 14.0946 22.1371 10.9206 21.5888 7.75113Z"
          fill="#494A4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_12788_103847">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CameraSimpleIcon;
