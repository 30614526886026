import { Translate, CaretDown } from "@asphalt-react/iconpack";
import IconTuner from "@asphalt-react/icontuner";
import Text from "components/shared/Text/Text.component";
import { useLanguage } from "providers/Language/Language.provider";
import { fromEnv } from "utils/env.util";
import style from "./PreLogin.module.css";

type Props = {
  toggleShowModal: () => void;
};

const PreLogin = ({ toggleShowModal }: Props) => {
  const { language } = useLanguage();

  if (fromEnv("REACT_APP_HIDE_LANGUAGE_SELECTOR") === "true") return null;

  return (
    <div
      className={style["pre-login__language"]}
      onClick={toggleShowModal}
      data-testid="page-header-pre-login-button"
    >
      <Translate />
      <Text bold fontSize="s" className={style["pre-login__language-label"]}>
        {language}
      </Text>
      <IconTuner size="s">
        <CaretDown />
      </IconTuner>
    </div>
  );
};

export default PreLogin;
