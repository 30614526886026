export const JSONTry = <T = string, D = any>(
  source: any,
  path: string,
  defaultValue?: D
): T | D => {
  const split = path.includes(".") ? path.split(".") : [path];

  const target = split.reduce((final, current) => {
    // ensures falsy values are returned correctly [0, false, null]
    if (typeof final === "undefined" || typeof final[current] === "undefined") {
      return undefined;
    }
    return final[current];
  }, source);

  if (typeof target === "undefined") {
    return defaultValue as D;
  }

  return target;
};
