const DocumentIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.455 32.239L27.246 31.082C26.934 30.794 26.452 30.815 26.178 31.132L24.732 32.79C24.44 33.125 23.92 33.127 23.627 32.793L22.201 31.175C21.908 30.842 21.388 30.841 21.095 31.169L19.612 32.824C19.319 33.147 18.818 33.151 18.525 32.834L16.966 31.158C16.664 30.835 16.144 30.846 15.861 31.183L14.472 32.824C14.189 33.149 13.698 33.173 13.395 32.877L11.505 31.046C11.222 30.77 10.768 30.768 10.485 31.043L9.247 32.226C8.775 32.677 8 32.343 8 31.695V7.407C8 6.631 8.633 6 9.408 6H28.238C29.041 6 29.702 6.657 29.702 7.468V31.705C29.702 32.353 28.927 32.686 28.455 32.239Z"
        fill="#FFC41C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9311 24.7224H12.7711C12.2411 24.7224 11.8101 24.2914 11.8101 23.7614C11.8101 23.2314 12.2411 22.7994 12.7711 22.7994H24.9311C25.4621 22.7994 25.8921 23.2314 25.8921 23.7614C25.8921 24.2914 25.4621 24.7224 24.9311 24.7224Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4726 33.8513C29.8356 33.5683 33.0086 31.9653 32.8456 27.4873C32.6816 23.0093 26.3316 19.8413 22.4496 23.8263C18.5666 27.8043 22.2186 35.1663 28.4726 33.8513Z"
        fill="#00AA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.001 31.0799C25.824 31.0799 25.647 31.0129 25.511 30.8769C25.241 30.6059 25.241 30.1679 25.512 29.8969L29.356 26.0579C29.626 25.7879 30.064 25.7879 30.335 26.0589C30.605 26.3289 30.605 26.7679 30.334 27.0369L26.49 30.8779C26.355 31.0129 26.178 31.0799 26.001 31.0799Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9962 31.0799C25.8192 31.0799 25.6422 31.0129 25.5062 30.8769L23.3712 28.7409C23.1002 28.4709 23.1002 28.0319 23.3712 27.7629C23.6412 27.4919 24.0792 27.4919 24.3502 27.7629L26.4852 29.8979C26.7562 30.1679 26.7562 30.6059 26.4852 30.8769C26.3502 31.0129 26.1732 31.0799 25.9962 31.0799Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9311 19.3914H12.7711C12.2411 19.3914 11.8101 18.9604 11.8101 18.4304C11.8101 17.9004 12.2411 17.4684 12.7711 17.4684H24.9311C25.4621 17.4684 25.8921 17.9004 25.8921 18.4304C25.8921 18.9604 25.4621 19.3914 24.9311 19.3914Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9311 13.864H12.7711C12.2411 13.864 11.8101 13.433 11.8101 12.903C11.8101 12.373 12.2411 11.941 12.7711 11.941H24.9311C25.4621 11.941 25.8921 12.373 25.8921 12.903C25.8921 13.433 25.4621 13.864 24.9311 13.864Z"
        fill="white"
      />
    </svg>
  );
};

export default DocumentIcon;
