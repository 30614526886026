import { Translate, CaretDown, Person, Help } from "@asphalt-react/iconpack";
import IconTuner from "@asphalt-react/icontuner";
import Button from "components/shared/Button/Button.component";
import Text from "components/shared/Text/Text.component";
import { SESSION_NAMES } from "constant/session.constant";
import { LANGUAGE } from "constant/language.constant";
import { useGenericModal } from "providers/GenericModal/GenericModal.provider";
import { useLanguage } from "providers/Language/Language.provider";
import { useTranslation } from "providers/Translation/Translation.provider";
import { useRef, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { deleteSession } from "utils/session.util";
import { fromEnv } from "utils/env.util";
import style from "./PostLogin.module.css";
import { HELP_URLS } from "constant/url.constant";
import { useClickstream } from "providers/Clickstream/Clickstream.provider";

type Props = {
  toggleShowModal: () => void;
};

const PostLogin = ({ toggleShowModal }: Props) => {
  const { language } = useLanguage();
  const translate = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { showGenericModal, hideGenericModal } = useGenericModal();
  const history = useHistory();
  const { track } = useClickstream();

  const toggleShowMenu = useCallback(() => setShowMenu(!showMenu), [showMenu]);

  const handleOnLogout = () => {
    track({
      eventName: "[GMWO] Warning Information Shown",
      navigation: { action: "Loaded" },
    });
    showGenericModal({
      template: "confirm",
      title: translate("generic.modal.signout.text.title"),
      message: translate("generic.modal.signout.text.message"),
      buttonPrimaryText: translate("generic.modal.signout.button.positive"),
      buttonSecondaryText: translate("generic.modal.signout.button.negative"),
      onSecondaryButtonClick: hideGenericModal,
      onPrimaryButtonClick: () => {
        hideGenericModal();
        deleteSession(SESSION_NAMES.ACCESS_TOKEN);
        deleteSession(SESSION_NAMES.REFRESH_TOKEN);
        history.replace("/");
      },
    });
  };

  const handleOnHelpClick = useCallback(() => {
    window.open(HELP_URLS.HOME, "_blank");
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as any)) {
        toggleShowMenu();
      }
    }

    if (showMenu) document.addEventListener("click", handleClickOutside);

    return (): void => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [toggleShowMenu, showMenu]);

  return (
    <div
      className={style["post-login"]}
      onClick={toggleShowMenu}
      ref={ref}
      data-testid="page-header-post-login-button">
      <IconTuner size="s">
        <Person />
      </IconTuner>
      <IconTuner size="s">
        <CaretDown />
      </IconTuner>

      {showMenu && (
        <div className={style["post-login__container"]}>
          <div
            className={style["post-login__list"]}
            data-testid="page-header-post-login-help-button"
            onClick={handleOnHelpClick}>
            <IconTuner size="s">
              <Help />
            </IconTuner>
            <div
              className={style["post-login__list__translation"]}
              onClick={handleOnHelpClick}>
              <Text bold fontSize="s">
                Help
              </Text>
            </div>
          </div>
          {fromEnv("REACT_APP_HIDE_LANGUAGE_SELECTOR") === "false" && (
            <div
              className={style["post-login__list"]}
              onClick={toggleShowModal}>
              <IconTuner size="s">
                <Translate />
              </IconTuner>
              <div className={style["post-login__list__translation"]}>
                <Text
                  color={language !== "en" ? "disabled" : "default"}
                  bold={language === "en"}
                  fontSize="s">
                  {LANGUAGE.EN}
                </Text>
                <Text fontSize="s">|</Text>
                <Text
                  color={language !== "id" ? "disabled" : "default"}
                  bold={language === "id"}
                  fontSize="s">
                  {LANGUAGE.ID}
                </Text>
              </div>
            </div>
          )}
          <div className={style["post-login__list"]}>
            <Button
              fullWidth
              variant="outline"
              onClick={handleOnLogout}
              data-testid="page-header-post-login-logout-button">
              Log out
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostLogin;
