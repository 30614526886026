import { HTMLAttributes } from "react";
import { classNames } from "utils/classnames.util";
import style from "./Card.module.css";

interface Props extends HTMLAttributes<HTMLDivElement> {
  type?: "nude" | "elevated";
}

const Card = ({ children, className, type = "elevated", ...rest }: Props) => {
  return (
    <div
      className={classNames(
        style["card"],
        style[`card__type--${type}`],
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Card;
