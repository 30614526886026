const CameraIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7248 14.2543L11.1558 14.9283C10.5778 15.0793 9.98678 14.7343 9.83578 14.1563L9.16178 11.5873C9.00978 11.0103 9.35578 10.4193 9.93278 10.2683L12.5018 9.59433C13.0798 9.44233 13.6708 9.78733 13.8218 10.3653L14.4958 12.9343C14.6478 13.5123 14.3018 14.1033 13.7248 14.2543Z"
        fill="#00AED6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0162 26.6635L13.8842 31.1575C11.7202 31.7255 9.50619 30.4315 8.93919 28.2675L6.63819 19.4955C6.07119 17.3325 7.36519 15.1185 9.52819 14.5515L26.6612 10.0575C28.8242 9.48954 31.0372 10.7835 31.6052 12.9465L33.9062 21.7195C34.4732 23.8825 33.1802 26.0965 31.0162 26.6635Z"
        fill="#B6202D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5113 24.7387L13.3793 29.2317C11.2153 29.8007 9.0013 28.5067 8.4343 26.3427L6.1333 17.5707C5.5663 15.4077 6.8603 13.1937 9.0233 12.6267L26.1563 8.13273C28.3193 7.56473 30.5323 8.85873 31.1003 11.0217L33.4013 19.7947C33.9683 21.9577 32.6753 24.1717 30.5113 24.7387Z"
        fill="#EE2737"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1639 18.9438L10.5949 19.6178C10.0179 19.7688 9.4259 19.4228 9.2749 18.8458L8.6009 16.2768C8.4499 15.6988 8.7949 15.1078 9.3719 14.9568L11.9419 14.2828C12.5189 14.1318 13.1099 14.4768 13.2619 15.0538L13.9349 17.6238C14.0869 18.2008 13.7419 18.7918 13.1639 18.9438Z"
        fill="#B6202D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.263 16.9496C30.263 20.1296 27.685 22.7076 24.506 22.7076C21.326 22.7076 18.749 20.1296 18.749 16.9496C18.749 13.7696 21.326 11.1926 24.506 11.1926C27.685 11.1926 30.263 13.7696 30.263 16.9496Z"
        fill="#FFC41C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0054 16.9496C28.0054 18.8836 26.4384 20.4496 24.5054 20.4496C22.5724 20.4496 21.0054 18.8836 21.0054 16.9496C21.0054 15.0166 22.5724 13.4506 24.5054 13.4506C26.4384 13.4506 28.0054 15.0166 28.0054 16.9496Z"
        fill="white"
      />
    </svg>
  );
};

export default CameraIcon;
