import GenericModalProvider from "providers/GenericModal/GenericModal.provider";
import LanguageProvider from "providers/Language/Language.provider";
import LoaderProvider from "providers/Loader/Loader.provider";
import TranslationProvider from "providers/Translation/Translation.provider";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { ClickstreamProvider } from "providers/Clickstream/Clickstream.provider";
import { LitmusProvider } from "providers/Litmus/Litmus.provider";

function App() {
  const baseurl = process.env.PUBLIC_URL;

  return (
    <Router basename={baseurl}>
      <LanguageProvider>
        <TranslationProvider languageFile={require("./translations.json")}>
          <LoaderProvider>
            <ClickstreamProvider>
              <GenericModalProvider>
                <LitmusProvider>
                  <Routes />
                </LitmusProvider>
              </GenericModalProvider>
            </ClickstreamProvider>
          </LoaderProvider>
        </TranslationProvider>
      </LanguageProvider>
    </Router>
  );
}

export default App;
