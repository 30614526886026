import { useCallback, useEffect, useState } from "react";
import style from "./PageHeader.module.css";
import ModalLanguage from "./components/ModalLanguage/ModalLanguage.component";
import PreLogin from "./components/PreLogin/PreLogin.component";
import PostLogin from "./components/PostLogin/PostLogin.component";
import { GopayMerchantIcon } from "../Icon";
import { setCookie } from "utils/cookies.util";
import { getSession } from "utils/session.util";
import { COOKIE_NAMES } from "constant/cookie.constant";
import { SESSION_NAMES } from "constant/session.constant";
import { useHistory } from "react-router-dom";
import {
  LOGIN_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
} from "constant/routes.constant";

const PageHeader = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const history = useHistory();

  const toggleShowModal = useCallback(
    () => setShowLanguageModal(!showLanguageModal),
    [showLanguageModal]
  );

  const handleSetLoggedIn = useCallback(() => {
    const tokenFromCookie = getSession(SESSION_NAMES.ACCESS_TOKEN);

    const validLoginPath = LOGIN_ROUTES.some((loginRoute) => {
      if (loginRoute.exact) {
        return loginRoute.path === history.location.pathname;
      }

      return history.location.pathname.includes(loginRoute.path);
    });

    const validPrivatePath = PRIVATE_ROUTES.some((privateRoute) =>
      history.location.pathname.includes(privateRoute.path)
    );

    const validPublicPath = PUBLIC_ROUTES.some((publicRoute) =>
      history.location.pathname.includes(publicRoute.path)
    );

    const validPathToSave =
      tokenFromCookie &&
      !validLoginPath &&
      (validPublicPath || validPrivatePath);

    if (validPathToSave) {
      setCookie(COOKIE_NAMES.STEP, history.location.pathname);
    }
    setLoggedIn(!!tokenFromCookie);
  }, [history.location.pathname]);

  useEffect(() => {
    handleSetLoggedIn();

    const unlisten = history.listen(() => {
      handleSetLoggedIn();
    });

    return () => unlisten();
  }, [handleSetLoggedIn, history]);

  return (
    <>
      <ModalLanguage show={showLanguageModal} toggleShow={toggleShowModal} />
      <div className={style["page-header"]}>
        <div data-testid="page-header-logo">
          <GopayMerchantIcon />
        </div>

        {loggedIn ? (
          <PostLogin toggleShowModal={toggleShowModal} />
        ) : (
          <PreLogin toggleShowModal={toggleShowModal} />
        )}
      </div>
    </>
  );
};

export default PageHeader;
