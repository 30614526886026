import Modal from "components/shared/Modal/Modal.component";
import { LANGUAGE } from "constant/language.constant";
import { ILanguageCode } from "providers/Language/Language.interface";
import { useLanguage } from "providers/Language/Language.provider";
import { useTranslation } from "providers/Translation/Translation.provider";
import { ChangeEvent, useCallback } from "react";
import style from "./ModalLanguage.module.css";

type Props = {
  show: boolean;
  toggleShow: () => void;
};

const ModalLanguage = ({ show, toggleShow }: Props) => {
  const { language, setLanguage } = useLanguage();
  const translate = useTranslation();

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLanguage(event.target.value as ILanguageCode);
      toggleShow();
    },
    [toggleShow, setLanguage]
  );

  return (
    <Modal onClose={toggleShow} show={show}>
      <Modal.Header
        onClose={toggleShow}
        data-testid="page-header-modal-languange-header"
      >
        {translate("generic.modal.language.title")}
      </Modal.Header>
      <Modal.Body>
        <div
          className={style["modal-language__option"]}
          data-testid="page-header-modal-language-option-en"
        >
          <label htmlFor="en">{LANGUAGE.EN}</label>
          <input
            id="en"
            type="radio"
            name="selectedLanguage"
            value="en"
            checked={language === "en"}
            onChange={handleOnChange}
          />
        </div>
        <div
          className={style["modal-language__option"]}
          data-testid="page-header-modal-language-option-id"
        >
          <label htmlFor="id">{LANGUAGE.ID}</label>
          <input
            id="id"
            type="radio"
            name="selectedLanguage"
            value="id"
            checked={language === "id"}
            onChange={handleOnChange}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalLanguage;
