import LoginWrapper from "components/Login/Wrapper/Wrapper.component";
import PageHeader from "components/shared/PageHeader/PageHeader.component";
import {
  LOGIN_ROUTES,
  PUBLIC_ROUTES,
  PRIVATE_ROUTES,
} from "constant/routes.constant";
import { AuthProvider } from "providers/Auth/Auth.provider";
import ContainerProvider from "providers/Container/Container.provider";
import { HttpProvider } from "providers/Http/Http.provider";
import { createElement, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { FullLoader } from "components/shared/FullLoader/FullLoader.component";
import { MobileView, BrowserView } from "react-device-detect";
import DesktopRedirectQR from "screens/DesktopRedirectQR/DesktopRedirectQR.component";
import { ClickstreamProvider } from "providers/Clickstream/Clickstream.provider";
import GenericModalProvider from "providers/GenericModal/GenericModal.provider";

const Routes = () => {
  return (
    <Suspense fallback={<FullLoader />}>
      <MobileView renderWithFragment>
        <Switch>
          {LOGIN_ROUTES.map((loginRoute, index) => (
            <Route key={index} exact={loginRoute.exact} path={loginRoute.path}>
              <PageHeader />
              <LoginWrapper>{createElement(loginRoute.screen)}</LoginWrapper>
            </Route>
          ))}
          {PUBLIC_ROUTES.map((publicRoute, index) => (
            <Route key={index} path={publicRoute.path}>
              <PageHeader />
              {createElement(publicRoute.screen)}
            </Route>
          ))}
          <Route path="/:containerId">
            <Switch>
              <HttpProvider injectToken>
                <AuthProvider>
                  <ClickstreamProvider>
                    <GenericModalProvider>
                      <ContainerProvider>
                        <PageHeader />
                        {PRIVATE_ROUTES.map((privateRoute, index) => (
                          <Route
                            key={index}
                            path={`/:containerId${privateRoute.path}`}>
                            {createElement(privateRoute.screen)}
                          </Route>
                        ))}
                      </ContainerProvider>
                    </GenericModalProvider>
                  </ClickstreamProvider>
                </AuthProvider>
              </HttpProvider>
            </Switch>
          </Route>
        </Switch>
      </MobileView>
      <BrowserView>
        <Switch>
          <Route>
            <DesktopRedirectQR />
          </Route>
        </Switch>
      </BrowserView>
    </Suspense>
  );
};

export default Routes;
