const NotesIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8254_57629)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7733 7.577C22.0763 10.525 22.0763 13.474 21.7733 16.423C21.4843 19.241 19.2423 21.483 16.4233 21.773C14.9493 21.924 13.4753 22 12.0002 22C10.5262 22 9.05125 21.924 7.57725 21.773C4.75825 21.483 2.51625 19.241 2.22725 16.423C1.92425 13.474 1.92425 10.525 2.22725 7.577C2.51625 4.759 4.75825 2.516 7.57725 2.226C9.05125 2.075 10.5262 2 12.0002 2C13.4753 2 14.9493 2.075 16.4233 2.226C19.2423 2.516 21.4843 4.759 21.7733 7.577ZM8.00025 16.9998H12.0002C12.5525 16.9998 13.0002 16.5521 13.0002 15.9998C13.0002 15.4475 12.5525 14.9998 12.0002 14.9998H8.00025C7.44797 14.9998 7.00025 15.4475 7.00025 15.9998C7.00025 16.5521 7.44797 16.9998 8.00025 16.9998ZM8.00025 12.9998H16.0002C16.5525 12.9998 17.0002 12.5521 17.0002 11.9998C17.0002 11.4475 16.5525 10.9998 16.0002 10.9998H8.00025C7.44797 10.9998 7.00025 11.4475 7.00025 11.9998C7.00025 12.5521 7.44797 12.9998 8.00025 12.9998ZM8.00025 8.9998H16.0002C16.5525 8.9998 17.0002 8.55208 17.0002 7.9998C17.0002 7.44752 16.5525 6.9998 16.0002 6.9998H8.00025C7.44797 6.9998 7.00025 7.44752 7.00025 7.9998C7.00025 8.55208 7.44797 8.9998 8.00025 8.9998Z"
          fill="#00AED6"
        />
      </g>
      <defs>
        <clipPath id="clip0_8254_57629">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotesIcon;
