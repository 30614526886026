const Calendar = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3.5C12.1 3.5 12.5 3 12.5 2.5V1.5C12.5 0.9 12.1 0.5 11.5 0.5C10.9 0.5 10.5 0.9 10.5 1.5V2.5C10.5 3 10.9 3.5 11.5 3.5ZM14.5 1.5H13.5V2.5C13.5 3.6 12.6 4.5 11.5 4.5C10.4 4.5 9.5 3.6 9.5 2.5V1.5H6.5V2.5C6.5 3.6 5.6 4.5 4.5 4.5C3.4 4.5 2.5 3.6 2.5 2.5V1.5H1.5C0.9 1.5 0.5 2 0.5 2.5V14.5C0.5 15.1 0.9 15.5 1.5 15.5H14.5C15.1 15.5 15.5 15.1 15.5 14.5V2.5C15.5 1.9 15 1.5 14.5 1.5ZM5.5 13.5H2.5V10.5H5.5V13.5ZM5.5 9.5H2.5V6.5H5.5V9.5ZM9.5 13.5H6.5V10.5H9.5V13.5ZM9.5 9.5H6.5V6.5H9.5V9.5ZM13.5 13.5H10.5V10.5H13.5V13.5ZM13.5 9.5H10.5V6.5H13.5V9.5ZM4.5 3.5C5.1 3.5 5.5 3 5.5 2.5V1.5C5.5 0.9 5.1 0.5 4.5 0.5C3.9 0.5 3.5 0.9 3.5 1.5V2.5C3.5 3 3.9 3.5 4.5 3.5Z"
        fill="#8292B0"
      />
    </svg>
  );
};

export default Calendar;
