import { COOKIE_NAMES } from "constant/cookie.constant";
import { SESSION_NAMES } from "constant/session.constant";
import { PropsWithChildren, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getCookie } from "utils/cookies.util";
import { getSession } from "utils/session.util";

const LoginWrapper = ({ children }: PropsWithChildren) => {
  const history = useHistory();

  useEffect(() => {
    const tokenFromCookie = getSession(SESSION_NAMES.ACCESS_TOKEN);
    const previousStep = getCookie(COOKIE_NAMES.STEP);
    if (!tokenFromCookie) return;

    if (previousStep) {
      history.replace(previousStep);
    }
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};

export default LoginWrapper;
