/**
 * Register environment variable names here for strict type checking
 */
export type EnvironmentVariables =
  | "REACT_APP_*"
  | "REACT_APP_OUTLETS_GOOGLE_MAPS_API_KEY"
  | "REACT_APP_HIDE_LANGUAGE_SELECTOR"
  | "REACT_APP_GOBIZ_API_HOST"
  | "REACT_APP_STATIC_FILE_HOST"
  | "REACT_APP_HELP_URL"
  | "REACT_APP_ALLOW_PLUS_IN_EMAIL"
  | "REACT_APP_DOCKER_IMAGE_TAG"
  | "REACT_APP_ONBOARDING_URL"
  | "REACT_APP_CLICKSTREAM_HOST"
  | "REACT_APP_CLICKSTREAM_KEY"
  | "REACT_APP_LITMUS_HOST"
  | "REACT_APP_LITMUS_PASSKEY"
  | "REACT_APP_LITMUS_CLIENT_ID"
  | "REACT_APP_LITMUS_CONFIG_NAME"
  | "REACT_APP_HARMONIA_REDIRECTION_URL"
  | "REACT_APP_HARMONIA_API_HOST"
  | "REACT_APP_REFRESH_TOKEN_TTL_IN_HOURS";

/**
 * In development, will attempt to retrieve environment variables from `process.env`, while on production, will return
 * value from server injected variable `$_ENV`.
 * @param variableName
 */
export const fromEnv = <T = any>(variableName: EnvironmentVariables): T => {
  const variable =
    process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
      ? process.env[variableName]
      : $_ENV[variableName];

  if (typeof variable === "undefined") {
    // Cannot use Logger.warn here since it would create a cyclic dependency
    console.warn(`Missing env variable ${variableName}`);
  }

  return variable as any;
};
