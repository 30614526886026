const BulbIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0175 12.1165C25.7775 16.9345 23.2965 22.2695 18.4875 24.0345C13.6665 25.7975 8.32745 23.3205 6.56745 18.5035C4.80745 13.6835 7.27745 8.3485 12.0965 6.5865C16.9165 4.8235 22.2465 7.2995 24.0175 12.1165Z"
        fill="#FFD400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5066 24.8596L15.9466 27.2616L9.55762 22.6256L24.3866 17.1996L22.5066 24.8596Z"
        fill="#FFD400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1475 25.8264C20.7165 24.0684 20.3365 22.2924 20.0465 20.5054C20.0275 20.3494 19.9975 20.1824 19.9665 20.0174C20.1175 19.9484 20.2675 19.8834 20.4175 19.8074C21.6675 19.1694 22.8465 17.3054 21.5065 16.1384C20.7965 15.5194 19.7675 15.7564 19.1975 16.4434C18.7475 16.9944 18.6365 17.6634 18.6475 18.3504C18.6575 18.6544 18.6875 18.9604 18.7075 19.2654C18.3365 19.4094 17.9665 19.5534 17.5865 19.6854C16.9875 19.8984 16.3765 20.1184 15.7675 20.3054C15.2565 19.4414 14.6875 18.5404 13.5775 18.4934C12.7475 18.4564 12.0375 18.9754 11.9175 19.8194C11.7775 20.7864 12.5175 21.4834 13.3765 21.7254C13.9775 21.8914 14.6075 21.8224 15.2275 21.6704C16.2075 23.3644 17.1265 25.1004 18.0175 26.8434C18.3575 27.4994 19.4265 27.0744 19.0865 26.4114C18.2075 24.6994 17.3075 22.9984 16.3465 21.3294C16.5375 21.2634 16.7275 21.1974 16.9175 21.1384C17.5575 20.9284 18.2165 20.7154 18.8575 20.4754C18.9875 21.3114 19.1675 22.1434 19.3275 22.9604C19.5375 23.9664 19.7565 24.9714 20.0065 25.9684C20.1765 26.6804 21.3275 26.5494 21.1475 25.8264ZM14.0575 20.6844C13.9075 20.6824 13.4265 20.5814 13.2165 20.3844C13.0575 20.2304 12.9775 19.9354 13.1475 19.7524C13.3175 19.5714 13.6575 19.6354 13.8575 19.7244C14.1365 19.8534 14.2965 20.1454 14.4665 20.4074C14.5065 20.4724 14.5465 20.5384 14.5865 20.6024C14.5375 20.6124 14.4875 20.6284 14.4265 20.6374C14.3275 20.6544 14.0465 20.6844 14.0575 20.6844ZM20.9265 17.3494C21.0375 17.9424 20.4265 18.4534 19.9875 18.7364C19.9875 18.7364 19.9875 18.7374 19.9875 18.7364C19.9775 18.7414 19.9665 18.7454 19.9475 18.7564C19.8975 18.7784 19.8465 18.8024 19.7965 18.8234C19.7675 18.4304 19.7565 18.0424 19.8465 17.6944C19.9665 17.1854 20.7565 16.4904 20.9265 17.3494Z"
        fill="#FFAE00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5468 26.9954L16.5268 29.5614C15.9378 29.7764 15.2868 29.4744 15.0768 28.8894L15.0468 28.7954C14.8268 28.2084 15.1268 27.5574 15.7168 27.3444L22.7278 24.7754C23.3178 24.5614 23.9668 24.8624 24.1878 25.4494L24.2168 25.5444C24.4268 26.1314 24.1268 26.7794 23.5468 26.9954Z"
        fill="#A0A1A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3572 29.2141L17.3362 31.7801C16.7562 31.9961 16.1072 31.6931 15.8862 31.1081L15.8572 31.0141C15.6362 30.4271 15.9372 29.7761 16.5272 29.5631L23.5462 26.9961C24.1272 26.7801 24.7772 27.0821 24.9972 27.6691L25.0272 27.7631C25.2472 28.3501 24.9372 28.9991 24.3572 29.2141Z"
        fill="#A0A1A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1268 29.2962C24.7868 31.1082 23.8568 33.1142 22.0468 33.7772C20.2368 34.4422 18.2278 33.5102 17.5668 31.6972C16.9068 29.8862 17.8368 27.8792 19.6478 27.2162C21.4568 26.5522 23.4668 27.4842 24.1268 29.2962Z"
        fill="#A0A1A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1365 29.7376C19.1475 29.0026 21.1875 28.3276 23.1765 27.5256C23.6165 27.3466 23.4265 26.6226 22.9775 26.8016C20.9875 27.6036 18.9475 28.2786 16.9375 29.0146C16.4875 29.1786 16.6765 29.9046 17.1365 29.7376Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.667 32.0647C19.527 31.4127 21.367 30.6997 23.217 30.0227C23.558 29.8987 23.887 29.7727 24.228 29.6577C24.688 29.5047 24.487 28.7797 24.027 28.9347C22.147 29.5687 20.297 30.3027 18.438 30.9887C18.117 31.1077 17.797 31.2287 17.467 31.3417C17.018 31.5017 17.207 32.2257 17.667 32.0647Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2567 17.844C30.6217 17.561 33.7867 15.958 33.6247 11.48C33.4637 7.002 27.1127 3.834 23.2297 7.818C19.3467 11.797 22.9967 19.158 29.2567 17.844Z"
        fill="#07A815"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7794 14.6106C26.6074 14.6106 26.4254 14.5436 26.2944 14.4076C26.0204 14.1356 26.0204 13.6986 26.2944 13.4276L30.1364 9.5896C30.4094 9.3196 30.8444 9.3186 31.1174 9.5896C31.3904 9.8596 31.3904 10.2986 31.1174 10.5686L27.2744 14.4086C27.1334 14.5436 26.9614 14.6106 26.7794 14.6106Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7791 14.6106C26.5971 14.6106 26.4251 14.5436 26.2941 14.4076L24.1501 12.2716C23.8861 12.0016 23.8861 11.5636 24.1501 11.2926C24.4231 11.0226 24.8571 11.0226 25.1311 11.2926L27.2641 13.4276C27.5381 13.6996 27.5381 14.1366 27.2641 14.4076C27.1331 14.5436 26.9511 14.6106 26.7791 14.6106Z"
        fill="white"
      />
    </svg>
  );
};

export default BulbIcon;
