const OkIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="default/business/mini_spot/ok">
        <g id="default/business/mini_spot/ok_2">
          <g id="default/business/mini_spot/ok_3">
            <path
              id="Fill 1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.4552 10.9962C20.4552 10.9962 18.4052 10.8072 18.4982 12.3302C18.5922 13.8532 19.8312 14.9962 20.8292 16.2812C21.8352 17.5662 22.6922 19.4712 22.6922 19.4712L24.8822 19.3272C24.8822 19.3272 23.4952 13.1402 20.4552 10.9962Z"
              fill="#D18070"
            />
            <path
              id="Fill 3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.2144 18.5655C23.2144 18.5655 20.3074 17.0435 18.9744 16.9475C17.6414 16.8525 14.6954 17.6145 13.5964 18.1375C12.5054 18.6615 12.9334 20.9945 15.0694 21.0885C17.2134 21.1835 18.2654 20.5185 19.6444 21.1365C21.0244 21.7555 21.3594 24.2785 19.8314 25.5155C18.3114 26.7545 15.8804 24.9935 15.3114 23.4215C14.7424 21.8515 13.6434 20.3285 11.2194 21.2795C11.2194 21.2795 10.6964 21.2325 11.2654 23.0885C11.8344 24.9455 12.0764 26.9445 12.7384 28.0875C13.4094 29.2305 16.5974 32.6575 18.4984 32.9425C20.4004 33.2285 24.5944 32.4195 25.7324 31.1335C26.8784 29.8485 29.3094 25.0415 29.1614 23.7545C29.0214 22.4695 27.7514 16.5765 27.1194 15.6615C26.2624 14.4245 22.7394 8.99845 21.3594 8.18945C19.9794 7.37745 18.5454 9.33145 19.7854 11.0925C21.0244 12.8535 22.9734 15.9485 23.2144 18.5655Z"
              fill="#F8A9AF"
            />
            <path
              id="Fill 6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.229 18.7421C25.216 18.2511 24.249 17.6831 23.275 17.1201C22.936 16.9241 22.439 17.0301 22.249 17.3891C22.06 17.7481 22.156 18.2061 22.518 18.4161C23.492 18.9781 24.459 19.5461 25.472 20.0371C25.824 20.2081 26.299 20.1451 26.498 19.7681C26.676 19.4311 26.606 18.9251 26.229 18.7421Z"
              fill="#D18070"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default OkIcon;
