import { HTMLAttributes } from "react";
import { classNames } from "utils/classnames.util";
import style from "./Title.module.css";

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
  className?: string;
  as?: keyof JSX.IntrinsicElements;
  fontSize?: "m" | "l";
}

const Title = ({
  children,
  className,
  as: As = "h1",
  fontSize = "m",
  ...rest
}: Props) => {
  return (
    <As
      {...rest}
      className={classNames(
        style["title"],
        style[`title__font-size--${fontSize}`],
        className
      )}
    >
      {children}
    </As>
  );
};

export default Title;
