const PromoIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3303 17.1755L22.0563 17.1945L22.0463 23.1795L17.9023 23.2895L18.3303 17.1755Z"
        fill="#004656"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.57227 18.9255L13.6593 11.4735C14.3803 10.5905 15.5063 10.0505 16.6313 10.2395C17.0953 10.3175 17.5473 10.4895 17.9563 10.7565C19.0093 11.4425 19.6113 12.6405 19.5333 13.8945L19.1713 23.7395L7.57227 18.9255Z"
        fill="#00AFDA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1243 26.8005C16.1363 29.8525 12.0493 30.7165 8.99725 28.7285C5.94325 26.7395 5.08025 22.6535 7.06925 19.6005C9.05725 16.5475 13.1433 15.6855 16.1963 17.6725C19.2493 19.6605 20.1123 23.7465 18.1243 26.8005Z"
        fill="#66CEE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6522 25.8407C15.1942 28.0807 12.1952 28.7147 9.95617 27.2557C7.71617 25.7977 7.08317 22.7987 8.54117 20.5597C10.0002 18.3197 12.9982 17.6867 15.2372 19.1447C17.4782 20.6037 18.1112 23.6017 16.6522 25.8407Z"
        fill="#E8E8E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1557 26.0135C11.7447 26.2605 10.3987 25.4995 9.89274 24.1595C9.64174 23.4925 8.56174 23.7815 8.81574 24.4555C9.54974 26.4025 11.4037 27.4505 13.4527 27.0905C14.1597 26.9665 13.8607 25.8895 13.1557 26.0135Z"
        fill="#33BEDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82893 22.328C9.91493 21.991 10.0459 21.693 10.2319 21.399C10.6169 20.789 9.65093 20.227 9.26693 20.835C9.03493 21.204 8.85993 21.608 8.75093 22.031C8.57193 22.728 9.64993 23.025 9.82893 22.328Z"
        fill="#33BEDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4258 18.9255L26.3388 11.4735C25.6168 10.5905 24.4918 10.0505 23.3668 10.2395C22.9028 10.3175 22.4498 10.4895 22.0408 10.7565C20.9888 11.4425 20.3868 12.6405 20.4648 13.8945L20.8258 23.7395L32.4258 18.9255Z"
        fill="#00AFDA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8737 26.8005C23.8617 29.8525 27.9487 30.7165 31.0007 28.7285C34.0547 26.7395 34.9177 22.6535 32.9297 19.6005C30.9407 16.5475 26.8547 15.6855 23.8017 17.6725C20.7487 19.6605 19.8857 23.7465 21.8737 26.8005Z"
        fill="#66CEE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3461 25.8407C24.8041 28.0807 27.8031 28.7147 30.0421 27.2557C32.2821 25.7977 32.9151 22.7987 31.4571 20.5597C29.9981 18.3197 27.0001 17.6867 24.7611 19.1447C22.5201 20.6037 21.8881 23.6017 23.3461 25.8407Z"
        fill="#E8E8E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0344 26.0135C26.6234 26.2605 25.2774 25.4995 24.7714 24.1595C24.5204 23.4925 23.4394 23.7815 23.6944 24.4555C24.4284 26.4025 26.2824 27.4505 28.3314 27.0905C29.0384 26.9665 28.7394 25.8895 28.0344 26.0135Z"
        fill="#33BEDE"
      />
    </svg>
  );
};

export default PromoIcon;
