const IncreaseIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5103 17.3899L21.1203 6.27994C20.7403 5.90994 20.1403 5.90994 19.7603 6.27994L8.3003 17.3899C7.6703 17.9999 8.1003 19.0699 8.9803 19.0699H12.2703C12.8103 19.0699 13.2503 19.5099 13.2503 20.0499V28.5399C13.2503 29.0799 13.6903 29.5199 14.2303 29.5199H26.5603C27.1003 29.5199 27.5403 29.0799 27.5403 28.5399V20.0499C27.5403 19.5099 27.9803 19.0699 28.5203 19.0699H31.8203C32.7003 19.0699 33.1303 17.9999 32.5003 17.3899H32.5103Z"
        fill="#00AA13"
      />
      <path
        d="M26.56 31.02H14.23C13.6888 31.02 13.25 31.4588 13.25 32V33.02C13.25 33.5613 13.6888 34 14.23 34H26.56C27.1012 34 27.54 33.5613 27.54 33.02V32C27.54 31.4588 27.1012 31.02 26.56 31.02Z"
        fill="#00AA13"
      />
      <path
        d="M17.7896 23.61C17.5296 23.61 17.2796 23.51 17.0796 23.32C16.6896 22.93 16.6896 22.3 17.0796 21.91L22.3096 16.68C22.6996 16.29 23.3296 16.29 23.7196 16.68C24.1096 17.07 24.1096 17.7 23.7196 18.09L18.4896 23.32C18.2896 23.52 18.0396 23.61 17.7796 23.61H17.7896Z"
        fill="white"
      />
      <path
        d="M17.9504 18.4701C17.4704 18.4701 17.0604 18.1401 16.9704 17.6501V17.6101C16.8704 17.0701 17.2404 16.5501 17.7904 16.4601C18.3404 16.3601 18.8504 16.7401 18.9404 17.2801C19.0304 17.8201 18.6704 18.3601 18.1204 18.4501C18.0604 18.4501 18.0004 18.4601 17.9504 18.4601V18.4701Z"
        fill="white"
      />
      <path
        d="M22.8505 23.55C22.5305 23.55 22.2105 23.39 22.0105 23.1C21.7105 22.64 21.8205 21.99 22.2805 21.69C22.7405 21.39 23.3505 21.49 23.6505 21.95L23.6805 22C23.9805 22.46 23.8605 23.08 23.4005 23.39C23.2305 23.5 23.0405 23.56 22.8505 23.56V23.55Z"
        fill="white"
      />
    </svg>
  );
};

export default IncreaseIcon;
