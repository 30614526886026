/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

/**
 * Deep merges two objects without modifying the input
 * @param target
 * @param source
 */
export const deepMerge = <T extends Partial<T>, U extends RecursivePartial<T>>(
  target: T,
  source: U
): T & U => {
  let output = Object.assign({}, target);

  // This line is already covered, but since it's recursive, jest will complain that it is not covered
  /* istanbul ignore next */
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      const _key = key as keyof T;
      if (isObject(source[_key])) {
        if (!(_key in target)) Object.assign(output, { [_key]: source[_key] });
        else output[_key] = deepMerge(target[_key], source[_key]);
      } else {
        Object.assign(output, { [_key]: source[_key] });
      }
    });
  }
  return output as T & U;
};
