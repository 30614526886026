import { Clickstream } from "@gojek/clickstream-web";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { getCookie, setCookie } from "utils/cookies.util";
import { useAuth } from "providers/Auth/Auth.provider";
import { COOKIE_NAMES } from "constant/cookie.constant";
import { fromEnv } from "utils/env.util";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
} from "react-device-detect";
import { products } from "@gojek/web-gopay-merchant-protos";
import { deepMerge } from "utils/deepMerge.util";
import { useQueryParams } from "hooks/useQueryParams";
import { getSessionId } from "utils/session.util";

const HOST = fromEnv("REACT_APP_CLICKSTREAM_HOST");
const SERVER_KEY = fromEnv("REACT_APP_CLICKSTREAM_KEY");

const clickstream = new Clickstream({
  event: {
    group: "web-gopay-merchant",
    classification: {
      instant: [],
    },
  },
  network: {
    url: HOST,
    headers: new Headers({
      Authorization: "Basic " + window.btoa(SERVER_KEY),
    }),
  },
});

export type IClickstreamContext = {
  track: (payload: any) => Promise<never> | undefined;
  pause: () => void;
  resume: () => void;
  free: () => Promise<never>;
  getMeta: () => object;
  getTimestamp: () => number;
  getCampaign: (path?: string, campaignTag?: string) => object;
  getDefaultPayload: () => object;
};

export const ClickstreamContext = createContext<IClickstreamContext>(
  {} as IClickstreamContext
);

export const useClickstream = () => {
  const context = useContext(ClickstreamContext);

  if (!context || typeof context === "undefined") {
    console.error(
      new Error("useClickstream is not wrapped with Clickstream Provider!")
    );
  }

  return context;
};

export const ClickstreamProvider = ({ children }: PropsWithChildren) => {
  const [deviceId, setDeviceId] = useState(
    getCookie(COOKIE_NAMES.GENERATED_DEVICE_ID)
  );
  const { user } = useAuth();
  const queryParams = useQueryParams();

  const getMeta = () => {
    return {
      eventGuid: uuidv4(),
      device: {
        browser: {
          name: browserName,
          userAgent: window.navigator.userAgent,
          version: browserVersion,
        },
        os: osName,
        osVersion: osVersion,
        platformType: mobileModel,
        platformBrand: mobileVendor,
        deviceId,
      },
      merchant: {
        saudagarId: "Not Set",
        user: {
          signedUpCountry: "ID",
          identity: user?.id || "Not Set",
          email: "Not Set",
          phone: user?.phone || "Not Set",
          merchantType: "Not Set",
        },
      },
      user: {
        signedUpCountry: "ID",
        identity: user?.id || "Not Set",
        email: "Not Set",
        phone: user?.phone || "Not Set",
        merchantType: "Not Set",
      },
      session: {
        sessionId: getSessionId(),
      },
    };
  };

  const getCampaign = (path?: string, campaignTag?: string) => {
    return {
      url: path || window.location.href,
      campaignTag: campaignTag || queryParams.campaignTag,
      sourceUrl: window.document.referrer,
      accessOrigin: "GMWO",
    };
  };

  const getDefaultPayload = () => {
    return {
      meta: getMeta(),
      campaign: getCampaign(),
      eventTimestamp: { seconds: getTimestamp() },
    };
  };

  const getTimestamp = () => {
    const date = new Date();
    return Math.floor(date.getTime() / 1000);
  };

  const _track = (properties: any) => {
    if (SERVER_KEY) {
      const { Component } = products.events.ui;
      const payload = Component.create(
        deepMerge(getDefaultPayload(), properties)
      );
      return clickstream.track(payload);
    }
  };

  const _free = () => {
    return clickstream.free();
  };

  const _pause = () => {
    return clickstream.pause();
  };

  const _resume = () => {
    return clickstream.resume();
  };

  useEffect(() => {
    if (!deviceId) {
      const generatedId = uuidv4();
      setCookie(COOKIE_NAMES.GENERATED_DEVICE_ID, generatedId);
      setDeviceId(generatedId);
    }
  }, [deviceId]);

  const value = {
    track: _track,
    pause: _pause,
    resume: _resume,
    free: _free,
    getMeta,
    getTimestamp,
    getCampaign,
    getDefaultPayload,
  };

  return (
    <ClickstreamContext.Provider value={value}>
      {children}
    </ClickstreamContext.Provider>
  );
};
