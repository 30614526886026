import { SESSION_NAMES } from "constant/session.constant";
import { useAuthApi } from "hooks/useAuthApi";
import { IUser } from "interfaces/auth.interface";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { getSession } from "utils/session.util";
import { Logger } from "utils/logger.util";

type IAuthContext = {
  isLoggedIn: boolean;
  user: IUser;
};

export const AuthContext = createContext({} as IAuthContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { getUserMeResponse } = useAuthApi();
  const history = useHistory();

  const isTokenCookieExist = useMemo(
    () => getSession(SESSION_NAMES.ACCESS_TOKEN) !== "",
    []
  );
  const [user, setUser] = useState<IUser>({} as IUser);

  const fetchApi = useCallback(async () => {
    if (!isTokenCookieExist) {
      history.replace("/");
      return;
    }
    const response = await getUserMeResponse();

    Logger.initUser(Number(response.data.user.id || 0));
    setUser(response.data.user);

    return response;
  }, [getUserMeResponse, history, isTokenCookieExist]);

  useEffect(() => {
    (async () => {
      await fetchApi();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context: IAuthContext = {
    user,
    isLoggedIn: isTokenCookieExist,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
