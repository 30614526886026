import Loader from "@asphalt-react/loader";
import style from "./FullLoader.module.css";

export const FullLoader = () => {
  return (
    <div className={style["full-loader-container"]}>
      <Loader size="m" />
    </div>
  );
};
