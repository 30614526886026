import { Cross } from "@asphalt-react/iconpack";
import { forwardRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { classNames } from "utils/classnames.util";
import Text from "../Text/Text.component";
import {
  ModalBodyProps,
  ModalHeaderProps,
  ModalProps,
} from "./Modal.interface";
import style from "./Modal.module.css";

const Modal = ({
  show,
  onClose,
  children,
  position = "bottom",
  fullWidth = true,
  fullHeight,
}: ModalProps) => {
  useEffect(() => {
    document.body.className = show
      ? style["modal__overflow--hide"]
      : style["modal__overflow--scroll"];
  }, [show]);

  if (!show) return null;

  return createPortal(
    <div
      className={classNames(
        style["modal"],
        style[`modal__position--${position}`]
      )}
    >
      <div className={style["modal__backdrop"]} onClick={onClose} />
      <div
        className={classNames(
          style["modal__container"],
          fullWidth && style["modal__container--full-width"],
          fullHeight && style["modal__container--full-height"]
        )}
      >
        {children}
      </div>
    </div>,
    document.getElementById("root")!
  );
};

const ModalHeader = ({
  children,
  hideCloseIcon,
  onClose,
  ...rest
}: ModalHeaderProps) => {
  const dataTestId = rest["data-testid"] ?? "modal-header";
  return (
    <div className={style["modal-header"]} {...rest}>
      <Text bold fontSize="l" data-testid={`${dataTestId}-title`}>
        {children}
      </Text>
      {!hideCloseIcon && (
        <div
          className={style["modal-header__close"]}
          onClick={onClose}
          data-testid={`${dataTestId}-close`}
        >
          <Cross />
        </div>
      )}
    </div>
  );
};

const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ children, className }, ref) => {
    return (
      <div ref={ref} className={classNames(style["modal-body"], className)}>
        {children}
      </div>
    );
  }
);

const ModalFooter = ({ children, className }: ModalHeaderProps) => {
  return (
    <div className={classNames(style["modal-footer"], className)}>
      {children}
    </div>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
