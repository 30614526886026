import { SESSION_NAMES } from "constant/session.constant";
import { RequestInitFetcher } from "interfaces/http.interface";
import { useGenericModal } from "providers/GenericModal/GenericModal.provider";
import { HttpContext } from "providers/Http/Http.provider";
import { useTranslation } from "providers/Translation/Translation.provider";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { deleteSession, getSession, setSession } from "utils/session.util";
import { GOID_TOKEN_API_URL, OTP_CLIENT_ID } from "constant/api.constant";
import { IGoidResponse } from "interfaces/auth.interface";
import { deepMerge } from "../utils/deepMerge.util";
import { COOKIE_NAMES } from "constant/cookie.constant";
import { deleteCookie, getCookie } from "utils/cookies.util";

export const useFetcher = () => {
  const httpOptions = useContext(HttpContext);
  const history = useHistory();
  const abortController = new AbortController();
  const { showGenericModal, hideGenericModal } = useGenericModal();
  const translate = useTranslation();

  const relogin = async (refreshToken: string) => {
    const refreshTokenResponse = await fetch(GOID_TOKEN_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Type": "go-id",
      },
      body: JSON.stringify({
        client_id: OTP_CLIENT_ID,
        grant_type: "refresh_token",
        data: { refresh_token: refreshToken },
      }),
    });

    if (!refreshTokenResponse.ok) return false;

    const refreshTokenResponseJson: IGoidResponse =
      await refreshTokenResponse.json();

    if (refreshTokenResponseJson?.access_token) {
      setSession(
        SESSION_NAMES.ACCESS_TOKEN,
        refreshTokenResponseJson.access_token
      );
      if (getCookie(COOKIE_NAMES.IS_SESSION_ACTIVE) === "true") {
        setSession(
          SESSION_NAMES.REFRESH_TOKEN,
          refreshTokenResponseJson.refresh_token || ""
        );
      } else {
        deleteSession(SESSION_NAMES.ACCESS_TOKEN);
        deleteSession(SESSION_NAMES.REFRESH_TOKEN);
      }
    }

    return true;
  };

  const fetcher = async <T = any>(
    input: RequestInfo | URL,
    init?: RequestInitFetcher,
    retries = 1
  ): Promise<Response & { data: T }> => {
    const authorizationHeaders = {
      headers: {
        Authorization: "Bearer " + getSession(SESSION_NAMES.ACCESS_TOKEN),
      },
    };

    let originRequest: RequestInitFetcher = {
      ...init,
      ...(httpOptions.injectToken && { ...authorizationHeaders }),
    };

    // Do deep merge in case request is done outside HTTPProvider inject but requires Authorization
    // Need to repopulate Authorization data since doing retry access token from params won't get updated
    if ((init?.headers as any)?.Authorization) {
      originRequest = deepMerge(originRequest, authorizationHeaders);
    }

    const options: RequestInitFetcher = {
      ...originRequest,
      headers: {
        "Content-Type": "application/json",
        ...originRequest.headers,
      },
      signal: abortController.signal,
    };

    try {
      const response = await fetch(input, options);

      const text = await response.text();
      const isResponseHtml = /<[^>]+>/.test(text);
      const refreshToken = getSession(SESSION_NAMES.REFRESH_TOKEN) || "";

      if (
        isResponseHtml &&
        !!refreshToken &&
        response.status === 401 &&
        retries > 0
      ) {
        const isSuccessRelogin = await relogin(refreshToken);
        if (!isSuccessRelogin) throw new Error("Failed to fetch new token!");
        return fetcher<T>(input, options, retries - 1);
      }

      const responseJson = JSON.parse(text);

      const isSessionExpired =
        (response.status === 401 &&
          responseJson?.errors?.[0]?.code === "HTTP_401") ||
        responseJson?.message === "invalid auth header" ||
        responseJson?.message === "Invalid/Expired token" ||
        responseJson?.message === "Session is revoked";

      if (!!refreshToken && isSessionExpired && retries > 0) {
        const isSuccessRelogin = await relogin(refreshToken);
        if (!isSuccessRelogin) throw new Error("Failed to fetch new token!");
        return fetcher<T>(input, options, retries - 1);
      } else if (!!!refreshToken && isSessionExpired) {
        throw new Error("no refresh token found!");
      }

      return Object.assign(response, {
        data: responseJson,
      });
    } catch (error: any) {
      abortController.abort();
      if (error?.message?.includes("token")) {
        showGenericModal({
          template: "error",
          title: translate("generic.modal.401.text.title"),
          message: translate("generic.modal.401.text.message"),
          buttonPrimaryText: translate("generic.modal.401.button.primary"),
          onPrimaryButtonClick: () => {
            deleteSession(SESSION_NAMES.ACCESS_TOKEN);
            deleteSession(SESSION_NAMES.REFRESH_TOKEN);
            deleteCookie(COOKIE_NAMES.IS_SESSION_ACTIVE);
            hideGenericModal();
            history.replace("/");
          },
        });
      }

      return new Promise((resolve) =>
        resolve({ ...new Response(), ok: false } as any)
      );
    }
  };

  return fetcher;
};
