const KtpIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.039 29.0075H7.959C6.877 29.0075 6 28.1315 6 27.0505V11.9565C6 10.8755 6.877 9.99951 7.959 9.99951H32.039C33.121 9.99951 33.997 10.8755 33.997 11.9565V27.0505C33.997 28.1315 33.121 29.0075 32.039 29.0075Z"
        fill="#66CEE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0428 26.2849H10.1208C9.69177 26.2849 9.34277 25.9359 9.34277 25.5069C9.34277 25.0779 9.69177 24.7289 10.1208 24.7289H16.0428C16.4718 24.7289 16.8198 25.0779 16.8198 25.5069C16.8198 25.9359 16.4718 26.2849 16.0428 26.2849Z"
        fill="#00AED6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7763 22.6999H10.1213C9.69226 22.6999 9.34326 22.3519 9.34326 21.9229C9.34326 21.4929 9.69226 21.1449 10.1213 21.1449H19.7763C20.2063 21.1449 20.5543 21.4929 20.5543 21.9229C20.5543 22.3519 20.2063 22.6999 19.7763 22.6999Z"
        fill="#00AED6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2129 26.2849H26.1529C25.4629 26.2849 24.9019 25.7239 24.9019 25.0339V19.9759C24.9019 19.2859 25.4629 18.7249 26.1529 18.7249H30.2129C30.9039 18.7249 31.4639 19.2859 31.4639 19.9759V25.0339C31.4639 25.7239 30.9039 26.2849 30.2129 26.2849Z"
        fill="#00AED6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7763 20.281H10.1213C9.69226 20.281 9.34326 19.933 9.34326 19.504C9.34326 19.074 9.69226 18.725 10.1213 18.725H19.7763C20.2063 18.725 20.5543 19.074 20.5543 19.504C20.5543 19.933 20.2063 20.281 19.7763 20.281Z"
        fill="#00AED6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4675 15.3806H7.47946C7.16946 15.3806 6.91846 15.1296 6.91846 14.8186C6.91846 14.5086 7.16946 14.2576 7.47946 14.2576H32.4675C32.7775 14.2576 33.0295 14.5086 33.0295 14.8186C33.0295 15.1296 32.7775 15.3806 32.4675 15.3806Z"
        fill="#EE2737"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2308 14.8191C14.2308 16.1691 13.1368 17.2631 11.7868 17.2631C10.4378 17.2631 9.34277 16.1691 9.34277 14.8191C9.34277 13.4691 10.4378 12.3761 11.7868 12.3761C13.1368 12.3761 14.2308 13.4691 14.2308 14.8191Z"
        fill="#FFC100"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.7352 13.1531H19.0802C18.6502 13.1531 18.3022 12.8051 18.3022 12.3761C18.3022 11.9461 18.6502 11.5971 19.0802 11.5971H28.7352C29.1652 11.5971 29.5132 11.9461 29.5132 12.3761C29.5132 12.8051 29.1652 13.1531 28.7352 13.1531Z"
        fill="#00AED6"
      />
    </svg>
  );
};

export default KtpIcon;
