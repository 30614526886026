const WithdrawIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8254_57624)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6534 21.8528L11.5884 21.7804L9.07241 18.5364C8.93291 18.3573 9.00806 18.0656 9.18486 18.0091L9.24741 17.9994H11.0938C11.0438 17.1614 11.0148 16.3214 10.9998 15.4804C10.9963 15.2503 11.0885 15.0546 11.2121 15.0093L11.2668 14.9994H12.7328C12.8828 14.9994 13.0048 15.2174 12.9998 15.4804C12.9905 16.0411 12.974 16.6013 12.9493 17.1608L12.9058 17.9994H14.7524C14.9504 17.9994 15.0593 18.2732 14.9661 18.473L14.9274 18.5364L12.4114 21.7804C12.206 22.0459 11.8807 22.07 11.6534 21.8528ZM5.25201 13.732C3.72501 13.61 2.48301 12.471 2.23901 10.959C1.92001 8.986 1.92001 7.013 2.23901 5.039C2.48301 3.528 3.72501 2.389 5.25201 2.267C9.75101 1.911 14.25 1.911 18.748 2.267C20.275 2.389 21.518 3.528 21.761 5.039C22.08 7.013 22.08 8.986 21.761 10.959C21.518 12.471 20.275 13.61 18.748 13.732C14.25 14.088 9.75101 14.088 5.25201 13.732ZM18 8.9994C18.552 8.9994 19 8.5524 19 7.9994C19 7.4464 18.552 6.9994 18 6.9994C17.448 6.9994 17 7.4464 17 7.9994C17 8.5524 17.448 8.9994 18 8.9994ZM6.00001 8.9994C6.55201 8.9994 7.00001 8.5524 7.00001 7.9994C7.00001 7.4464 6.55201 6.9994 6.00001 6.9994C5.44801 6.9994 5.00001 7.4464 5.00001 7.9994C5.00001 8.5524 5.44801 8.9994 6.00001 8.9994ZM12 10.9994C13.657 10.9994 15 9.6564 15 7.9994C15 6.3424 13.657 4.9994 12 4.9994C10.343 4.9994 9.00001 6.3424 9.00001 7.9994C9.00001 9.6564 10.343 10.9994 12 10.9994Z"
          fill="#00AED6"
        />
      </g>
      <defs>
        <clipPath id="clip0_8254_57624">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WithdrawIcon;
