const LocationIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.50018 0.5C4.22018 0.5 2.36768 2.2925 2.36768 4.4975C2.36768 6.71 6.50018 12.5 6.50018 12.5C6.50018 12.5 10.6327 6.71 10.6327 4.4975C10.6252 2.2925 8.78018 0.5 6.50018 0.5ZM6.50018 6.5C5.46518 6.5 4.62518 5.66 4.62518 4.625C4.62518 3.59 5.46518 2.75 6.50018 2.75C7.53518 2.75 8.37518 3.59 8.37518 4.625C8.37518 5.66 7.53518 6.5 6.50018 6.5Z"
      fill="#0085AD"
    />
  </svg>
);

export default LocationIcon;
