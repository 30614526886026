import { lazy } from "react";

const Login = lazy(() => import("screens/Login/Login.screen"));
const Register = lazy(() => import("screens/Register/Register.screen"));
const OverviewOfSteps = lazy(
  () => import("screens/OverviewOfSteps/OverviewOfSteps.screen")
);
const ProductSelection = lazy(
  () => import("screens/ProductSelection/ProductSelection.screen")
);
const OwnerKtpDetails = lazy(
  () => import("screens/OwnerKtpDetails/OwnerKtpDetails.screen")
);
const ServiceAvailability = lazy(
  () => import("screens/ServiceAvailability/ServiceAvailability.screen")
);
const Overview = lazy(() => import("screens/Overview/Overview.screen"));

const BankInformation = lazy(
  () => import("screens/BankInformation/BankInformation.screen")
);
const BusinessInformation = lazy(
  () => import("screens/BusinessInformation/BusinessInformation.screen")
);
const KycSuccess = lazy(() => import("screens/KycSuccess/KycSuccess.screen"));
const TermsAndConditions = lazy(
  () => import("screens/TermsAndConditions/TermsAndConditions.screen")
);
const RedirectToApp = lazy(
  () => import("screens/RedirectToApp/RedirectToApp.screen")
);

export const PRIVATE_ROUTES = [
  {
    path: "/bank-info",
    screen: BankInformation,
  },
  {
    path: "/business-information",
    screen: BusinessInformation,
  },
  {
    path: "/overview",
    screen: Overview,
  },
  {
    path: "/product-selection",
    screen: ProductSelection,
  },
  {
    path: "/owner-ktp-details",
    screen: OwnerKtpDetails,
  },
  {
    path: "/overview-of-steps",
    screen: OverviewOfSteps,
  },
  {
    path: "/service-availability",
    screen: ServiceAvailability,
  },
  {
    path: "/kyc-success",
    screen: KycSuccess,
  },
];

export const LOGIN_ROUTES = [
  {
    path: "/",
    exact: true,
    screen: Login,
  },
  {
    path: "/daftar",
    screen: Register,
  },
];

export const PUBLIC_ROUTES = [
  { path: "/terms-and-conditions", screen: TermsAndConditions },
  { path: "/redirect-to-gobiz", screen: RedirectToApp },
];
