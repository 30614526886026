import { ButtonHTMLAttributes, ReactNode } from "react";
import { classNames } from "utils/classnames.util";
import style from "./Button.module.css";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "outline";
  fullWidth?: boolean;
  className?: string;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  size?: "s" | "default";
}

const Button = ({
  children,
  fullWidth,
  variant = "primary",
  className,
  icon,
  iconPosition = "left",
  size = "default",
  ...rest
}: Props) => {
  return (
    <button
      {...rest}
      className={classNames(
        style["button"],
        style[`button__variant--${variant}`],
        style[`button__icon-position--${iconPosition}`],
        size !== "default" && style[`button__size--${size}`],
        fullWidth && style["button__fullWidth"],
        className
      )}
    >
      {icon && icon}
      {children}
    </button>
  );
};

export default Button;
